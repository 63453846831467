import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonRefresher, IonRefresherContent, IonSearchbar, IonTitle, IonToolbar} from '@ionic/react';
import DriverCard from './DriverCard';
import {RefresherEventDetail} from '@ionic/core';
import {ApiService} from "../api/ApiService";
import {DriverInfo} from "../api/model/Drivers";
import { refreshOutline } from 'ionicons/icons';
import { userStore } from '../api/AuthService';

const DMDriverSelect: React.FC = () => {

    const [drivers, setDrivers] = useState<DriverInfo[]>();
    const [filteredDrivers, setFilteredDrivers] = useState<DriverInfo[]>();

    useEffect(() => {
        ApiService.getManagerDrivers( "dmDriverSelect", userStore.getState().dmUserCode !== null ? userStore.getState().dmUserCode : localStorage.getItem("dmUserCode")).then(it => {
            const parsedDrivers = Object.keys(it).sort().map(key => {
                return {driverCode: key, name: it[key].name, phone: it[key].phone, unit: it[key].unit};
            })
            parsedDrivers.sort((a, b) => !a.unit ? 1 : !b.unit ? -1 : 0);
            setDrivers(parsedDrivers);
            setFilteredDrivers(parsedDrivers);
        });
    }, []);

    function filterDriverManagers(searchText: string): void {
        if (searchText?.length < 3) {
            setFilteredDrivers(drivers);
            return;
        }
        const normalizedText = searchText.toLowerCase();

        setFilteredDrivers(drivers?.filter(it => it.driverCode?.toLowerCase().includes(normalizedText) || it.name?.toLowerCase().includes(normalizedText) || it.unit?.includes(normalizedText)));
    }

    function doRefresh(event: CustomEvent<RefresherEventDetail>) {
            window.location.replace('./dmdriverselect');
        event.detail.complete();
      }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Settings" text=""/>
                        <IonIcon slot="end" icon="refreshOutline" onClick={e => doRefresh}></IonIcon>
                    </IonButtons>
                    <IonTitle>Select Driver</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                    <IonRefresherContent pullingIcon={refreshOutline} pullingText="Pull to refresh" refreshingSpinner="circles"></IonRefresherContent>
                </IonRefresher>
                <div style={{marginBottom: "9vh"}}>
                    <IonSearchbar color="light" placeholder="Search..." animated={true} debounce={150} onIonChange={(e: any) => filterDriverManagers(e.detail.value)}></IonSearchbar>
                    {filteredDrivers?.length ?
                        filteredDrivers?.map(it => <IonItem lines="none" key={it.driverCode}><DriverCard model={it}/></IonItem>) :
                        <p className="text-center color-black">No drivers found!</p>}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default DMDriverSelect;
