import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import ContactCard from './ContactCard';
import {ApiService} from "../api/ApiService";
import {ContactsInfo} from "../api/model/Contacts";
import {userStore} from "../api/AuthService";

const ChainOfCommand: React.FC = () => {

  const [noDMContact, setNoDMContact] = useState(false)
  const [dm, setDM] = useState<ContactsInfo>()
  const [lead, setLead] = useState<ContactsInfo>()
  const [terminalManager, setTerminalManager] = useState<ContactsInfo>()

    useEffect(() => {
        ApiService.getContacts("chainContact", {category: 'chainOfContact'}).then(it => {
            handleChain(it)
        });
        // getTeamLeadContact();
    }, []);

    async function handleChain(contacts: ContactsInfo []) {
        let temp: ContactsInfo;
        let tempLead: ContactsInfo;
        let tempManager: ContactsInfo
        temp = contacts.filter(el => el.email === userStore.getState().driver?.dmEmail)[0];
        tempLead = contacts.filter(el => (el.name === temp.subcategory && el.subcategory !== el.name))[0];
        tempManager = contacts.filter(el => (el.name === tempLead.subcategory && el.subcategory !== el.name))[0];
        if (temp) {
            setDM(temp);
            setLead(tempLead);
            setTerminalManager(tempManager);
        } else {
            setNoDMContact(true);
        }
    }


  return (
    <IonPage>
 <IonHeader >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Chain of Contact</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {!noDMContact ? (
          <div>
        <IonItem lines="none"> <ContactCard contact={{name: dm?.name, email: dm?.email, subcategory: "Driver Manager", phone: dm?.phone}}/></IonItem>
        {lead?.name ? <IonItem lines="none"> <ContactCard contact={{name: lead?.name, email: lead?.email, subcategory: "Team Lead", phone: lead?.phone}}/></IonItem> : null}
        {terminalManager?.name ? <IonItem lines="none"> <ContactCard contact={{name: terminalManager?.name, email: terminalManager?.email, subcategory: "Terminal Manager", phone: terminalManager?.phone}}/></IonItem> : null}
          </div>

        ) : <div style={{ color: "#666667", fontWeight: "bold", padding: "16px", backgroundColor: "white", margin: "16px", borderRadius: "10px"}}>
          It appears your chain of contact is still being set up. For a specific issue, please call <a href="tel:800-316-7160">(800) 316-7160</a>
          <br/><br/>
          <p>To set up your chain of contact, or any other app related question, email us at <a href="mailto:driverappfeedback@westernexp.com">driverappfeedback@westernexp.com</a></p>

          </div>}


      </IonContent>
    </IonPage>
  );
};

export default ChainOfCommand;
