import { useState, useEffect, useRef } from 'react';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonModal, IonButtons, IonBackButton, IonCardContent, IonTextarea, IonItemGroup, IonItemDivider, IonItem, IonLabel, IonSearchbar, IonSelect, IonSelectOption, IonToast, IonIcon } from '@ionic/react';
import { base64FromPath } from '@ionic/react-hooks/filesystem';
import {config} from '../Constants';
import CryptoJS from 'crypto-js';
import md5 from 'crypto-js/md5';
import "../theme/qrCodeScanner.css"
import { camera, cameraOutline, trashOutline, arrowDown, arrowUp, qrCode } from 'ionicons/icons';
//Google Analytics:
import AnimateHeight from 'react-animate-height';
import {sendErrorMsg} from '../components/DiscordHookError';
import { Camera } from '@capacitor/camera';
import React from 'react';
import QrFrame  from "../imgs/qr-frame.svg"
import logoutIcon from "../imgs/logout.png"

// Example images //
import trailerFront from "../imgs/tarp_photo/trailerFront.jpg";
import trailerRear from "../imgs/tarp_photo/trailerRear.jpg";
import trailerSide from "../imgs/tarp_photo/trailerSide.jpg";

import { ApiService } from '../api/ApiService';
import { toastStore } from '../components/AppToast';
import QrScanner from 'qr-scanner';
import { BrowserMultiFormatReader, BrowserCodeReader } from '@zxing/browser';

const timages: any[] = [];

interface Photo {
  filepath: string;
  webviewPath?: string;
  data: any;
}

var c = document.getElementById("canv");
// var $ = c!.getContext("2d");
let hashArray: any = [];

const GuardDog: React.FC<{moduleHistory: string}> = ({ moduleHistory }) => {
  const video: any = document.querySelector('video');
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const codeReader = new BrowserMultiFormatReader();
  
  
  const [qrOn, setQrOn] = useState<boolean>(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorModal2, setShowErrorModal2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [reportType, setReportType] = useState<string>("outbound")
  const [successPhoto, setSuccess] = useState(false);
  const [toast, setToast] = useState<string>("")
  const [photoMsg, setPhotoMsg] = useState<string>("Take photo");
  const [images, setImages] = useState<Photo[]>([]);
  const [licensePhoto, setLicensePhoto] = useState<Photo[]>([])
  const [unitVIN, setUnitVIN] = useState<string>("");
  const [unitMake, setUnitMake] = useState<string>("");
  const [unitPlate, setUnitPlate] = useState<string>("");
  const [unitYear, setUnitYear] = useState<string>("");
  const [unitId, setUnitId] = useState<string>("");
  const [loadNumber, setLoadNumber] = useState<string>("");
  const [dispatchNumber, setDispatchNumber] = useState<string>("");
  const [unitNumber, setUnitNumber] = useState("");
  const [unitData, setUnitData] = useState<any>({});
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [trailerNumber, setTrailerNumber] = useState("");
  const [dmCode, setDmCode] = useState("");
  const [division, setDivision] = useState("");
  const [height2, setHeight2] = useState(false);
  const [height3, setHeight3] = useState(false);
  const [scannedResult, setScannedResult] = useState<any>("");
  const [videoDeviceArray, setVideoDeviceArray] = useState<any>([])

  // const constraints = {
  //   video: {
  //     width: {
  //       ideal: 4096,

  //     },
  //     height: {
  //       ideal: 2160,
  //     },
  //     facingMode: 'environment'
  //   }
  // };

  const constraints = {
    video: {
      width: {
        min: 1280,
        ideal: 1920,
        max: 2560,
      },
      height: {
        min: 720,
        ideal: 1080,
        max: 1440
      },
      facingMode: 'environment'
    }
  };

  useEffect(() => {
    const deviceList = BrowserCodeReader.listVideoInputDevices()
    .then(VideoInputDevice => {
      setVideoDeviceArray(VideoInputDevice);
      console.log("videoDevices", VideoInputDevice);
    })
  },[]);

  enum CameraResultType {
    Uri = 'uri',
    Base64 = 'base64',
    DataUrl = 'dataUrl'
  }

  const handleStartScanner = (status: boolean) => {
    if (status) {
      setQrOn(false);
      
    } else {
      // video?.play();
      startCamera();
      setQrOn(true);
    }
  }


  async function startCamera() {
    const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
    video!.srcObject! = mediaStream;
    codeReader.decodeFromVideoDevice(
      videoDeviceArray[0].id,
      video,
      (result, error) => {
        // console.log("result", result);
        if (result !== null && result !== undefined) {
          // console.log("typeof result", typeof result, result);
          if (result.getText().indexOf("{") !== -1) {
            console.log("QR Result", result.getText());
            parseUnitData(result.getText())
          } else {
            setUnitVIN(result.getText())
            console.log("Barcode result", result.getText());
          }
          setQrOn(false);
        }
        // if (error) console.log("error", error);
      }
    );
  }

  const b64toBlob = (base64:any) => 
  fetch(base64).then(res => res.blob())
  
  async function submitReport() {
    setSubmitDisabled(true);
    console.log("images", images.length);
    if (images.length >= 1) {
      setLoading(true);
      let ln: any = null;
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "genericReportSubmission", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token'), load: ln, trailer: trailerNumber, unit: unitNumber, comment: 'Empty'}}] }))
      const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(async json => {if (json.results[0].errcode === "SYSERR") {sendErrorMsg("SYSERR: genericReportSubmission - PhotoUpload")} else if (json.results[0].load) { setLoadNumber(json.results[0].load.loadNumber)}{let refId = json.results[0].genericReportId; submitGuardDogReport(refId); submitPhotos(refId, ln);}})
    } else {
        setToast("Please ensure you have the required number of photos for a submission (2)")
        setShowToast(true);
      }
    }

    async function submitPhotos(refId: any, ln: any) {
      // Submit pictures --
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "photoSubmission", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token'), refId: refId, load: ln, division: division, trailer: trailerNumber, unit: unitNumber, type: "guarddog", dmCode: dmCode, dispatchNumber: dispatchNumber}}] }))
      const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then( async () => {uploadPhotos(refId)})
    }

    async function uploadPhotos(refId: any) {
      var data = new FormData();
      data.append("payload", JSON.stringify({ cmds: [{cmd: "photoUpload", args: {loginToken: localStorage.getItem('token'), refId: refId, type: "guarddog", dmCode: dmCode}}] }))
      for (let i = 0; i < images.length; i++) {
        data.append(`photos${i}`, new Blob([(await b64toBlob(images[i].data))],{type: 'application/octet-stream'}));
      }
      for (let i = 0; i < licensePhoto.length; i++) {
        data.append(`photos${i}`, new Blob([(await b64toBlob(images[i].data))],{type: 'application/octet-stream'}));
      }
      const requestOptions = {
        method: 'POST',
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then(json => {if(json.results[0].errmsg){setShowErrorModal(true); }else{setLoading(false); setSuccess(true); console.log("photos successful", json.results[0]);}})
    }

    async function submitGuardDogReport(refId: any) {
      // Submit pictures --
      var data = new FormData()
      data.append("payload", JSON.stringify({ cmds: [{cmd: "guardReportSubmit", args: {driverCode: localStorage.getItem('username')?.toUpperCase(), loginToken: localStorage.getItem('token'), refId: refId, unitId: unitId, unitVIN: unitVIN, unitYear: unitYear, unitMake: unitMake, unitPlate: unitPlate, type: reportType, location: location}}] }))
      const requestOptions = {
        method: 'POST',
        headers: {},
        body: data
      };
      fetch(config.url.BASE_URL, requestOptions)
      .then(response => response.json())
      .then( async () => {uploadPhotos(refId)})
    }

    async function takePicture(type: string) {
      const image = await Camera.getPhoto({
        quality: 30,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });
      const fileName = new Date().getTime() + '.jpeg';
      // await addDateTime(image.webPath!)
      const base64Data = await base64FromPath(image.webPath!);
        console.log("data");
        const newPhotos = [{
          filepath: fileName,
          webviewPath: image.webPath, 
          data: base64Data
        }, ...images];
        if (type === "portrait") {
          setImages(newPhotos)
        } else {
          setLicensePhoto(newPhotos)
        }
        timages.push(image)
        if (timages.length > 0) {
          setPhotoMsg("Take another photo")
        }
    }

    const removePhoto = (e: any) => {
      console.log("hashArasyaya", hashArray);
      const name = e.filepath;
      var hash = CryptoJS.MD5(e.data).toString();
      console.log("test remove", hash, hashArray);
      setImages(images.filter(image => image.filepath !== name));
    }

    const onScanSuccess = (result: QrScanner.ScanResult) => {
      console.log("scan result", result);
      if(result.data !== '') {
        parseUnitData(result.data)
        setQrOn(false);
      }
    }

    const onScanFail = (err: any) => {
      // console.log("scan error", err);
    }

    function parseUnitData(unitData: string) {
      let tempObj: any = JSON.parse(unitData)
      console.log("Unit data", tempObj.unitVIN.trim());
      setUnitVIN(tempObj.unitVIN.trim());
      setUnitMake(tempObj.unitMake.trim());
      setUnitPlate(tempObj.unitPlate.trim());
      setUnitId(tempObj.unitId.trim());
      setUnitYear(tempObj.unitYear.trim());
      setUnitData(tempObj);
    }

    
  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="primary">
          <IonTitle>Guard Dog</IonTitle>
          <IonButtons slot="end">
              <a>
                <img style={{height: "28px", marginRight: "15px", color: "white"}} onClick={() => ApiService.userLogout("borderInspections")} src={logoutIcon} alt="logout" />
              </a>
          </IonButtons>
        </IonToolbar> 
      </IonHeader>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toast}
        color="danger"
        duration={5000}
      />
       <IonModal isOpen={loading} id="transparent">
        <div className="modal-div">
          <div id="modal">
            <div className='loading-div'>
              <p>Please wait... your report is being submitted.</p>
              <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                <div className="stage">
                  <div className="dot-flashing"></div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </IonModal>
      <IonModal isOpen={showErrorModal2} id="transparent">
        <div className="modal-div">
          <div id="modal">
            <div className='warning-div' style={{backgroundColor: "#e9e9e9 !important", color: "#fff !important"}}>
              <h3 style={{textAlign: "center", fontWeight: "600"}}>Error!</h3>
              <p>This photo has already been submitted. Please select a new photo that has not been previously submitted.</p>
              <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                <div className="stage">
                  <IonButton id="western-button" expand="block" onClick={e => {setShowErrorModal2(false)}}><span style={{fontWeight: "600"}}>I Understand</span></IonButton>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </IonModal>
      <IonToast
        isOpen={successPhoto}
        onDidDismiss={() => {setLoading(false); setSuccess(false)}}
        message="Your Report was submitted."
        color="success"
        duration={3000}
      />
       <IonToast
        isOpen={showErrorModal}
        message="Error! you're report could not uploaded, check your connection and please try again."
        color="danger"
        duration={5000}
      />
      <IonContent color='white'>
        <div className='main-2'>
          <div>
            <IonItem color="white" lines="none">
              <div className='item-container'>
                <h1>Submit Vehicle Details</h1>
                <p style={{fontSize: "medium"}}><b>Please submit the following of the Unit and Driver: </b></p>
              <h4>Report Type:</h4>
              <div className="input-container" style={{margin: "auto auto 20px auto", width: "auto"}}>
                  <select id="accident" className="input" placeholder=" " value={reportType} onChange={e => setReportType(e.target.value!)} style={{ height: "20px", textAlign: "center", backgroundColor: "#f0f0f0"}}>
                      <option value={"outbound"}>Outbound</option>
                      <option value={"inbound"}>Inbound</option>
                  </select>
              </div>
              <h4>Terminal:</h4>
              <div className="input-container" style={{margin: "auto auto 20px auto", width: "auto"}}>
                  <select id="accident" className="input" placeholder=" " value={location} onChange={e => setLocation(e.target.value!)} style={{ height: "20px", textAlign: "center", backgroundColor: "#f0f0f0"}}>
                      <option value={"nashville"}>Nashville</option>
                  </select>
              </div>
              <div style={{ textAlign: "center"}} onClick={ e => {setHeight2(!height2); console.log("expanded");}} aria-expanded={ height2 !== false }>
                <div className='western-button-link'>
                    <div id="current-load-button" style={{background: "#e9e9e9"}}>
                        <p style={{paddingLeft: "15px"}}>Driver Photo</p>
                        <p style={images.length === 0 ? {color: "red"} : {color: "#4c8f2d"}}>{images.length > 0 ? "Done" : "Photo Required"}</p>
                        <img src={height2 === true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                    </div>
                </div>
              </div>
              <AnimateHeight style={ height2 === true ? {overflowY: 'scroll', borderRadius: "10px", textAlign: "center" } : { overflowY: 'scroll'}} duration={500} height={ height2 === true ? 'auto' : 0 } id="loadSection">
                { images.length > 0 ? <img src={images[0]?.webviewPath} alt="Front Portrait" style={{borderRadius: "8px"}}/> : null } 
                <button className='button' style={{margin: "18px auto"}} onClick={e => {takePicture("portrait")}}><p>{photoMsg}</p><IonIcon icon={cameraOutline}></IonIcon></button>
              </AnimateHeight>

              <div style={{ marginBottom: "14px", textAlign: "center"}} onClick={e => {setHeight3(!height3); console.log("expanded");}} aria-expanded={ height3 !== false }>
                <div className='western-button-link'>
                    <div id="current-load-button"  style={{background: "#e9e9e9"}} >
                        <p style={{paddingLeft: "15px"}}>Driver's License</p>
                        <p style={licensePhoto.length === 0 ? {color: "red"} : {color: "#4c8f2d"}}>{ licensePhoto.length > 0 ? "Done" : "Photo Required"}</p>
                        <img src={height3 === true ? arrowUp : arrowDown} style={{ height: "2vh", paddingRight: "15px"}} alt="arrow"/>
                    </div>
                </div>
              </div>
              <AnimateHeight style={ height3 === true ? {overflowY: 'scroll', borderRadius: "10px", textAlign: "center" } : { overflowY: 'scroll'}} duration={500} height={ height3 === true ? 'auto' : 0 } id="loadSection">
                { licensePhoto.length > 0 ? <img src={licensePhoto[0]?.webviewPath} alt="Driver's License" style={{borderRadius: "8px"}}/> : null } 
                <button className='button' style={{margin: "18px auto"}} onClick={e => {takePicture("license")}}><p>{photoMsg}</p><IonIcon icon={cameraOutline}></IonIcon></button>
              </AnimateHeight>
              <p style={{fontSize: "medium"}}><b>Scan the QR or Barcode located in the driver-side door frame to auto-fill the following information: </b></p>
              <button className='button' style={{margin: "5px auto 18px auto", background: "#212121"}} onClick={e => handleStartScanner(qrOn)}><p>{qrOn ? "Turn Off Camera" : "Scan Code"}</p><IonIcon icon={qrCode}></IonIcon></button>
              <div className="qr-reader">
                {/* QR */}
                <video muted autoPlay id='videoDiv' style={{borderRadius: "5px"}}></video>
                <div ref={qrBoxEl} className="qr-box">
                  <img src={QrFrame} alt="Qr Frame" width={256} height={256} className="qr-frame"/>
                </div>
              </div>
              <IonItemGroup>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="orientation" className="input" type="text" placeholder="" value={unitId} onChange={e => {setUnitId(e.target?.value)}}/>
                      <p className="placeholder">Unit Id</p>
                    </div>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="orientation" className="input" type="text" placeholder="" value={unitVIN} onChange={e => {setUnitVIN(e.target?.value)}}/>
                      <p className="placeholder">VIN</p>
                    </div>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="orientation" className="input" type="text" placeholder="" value={unitMake} onChange={e => {setUnitMake(e.target?.value)}}/>
                      <p className="placeholder">Make</p>
                    </div>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="orientation" className="input" type="text" placeholder="" value={unitYear} onChange={e => {setUnitYear(e.target?.value)}}/>
                      <p className="placeholder">Year</p>
                    </div>
                  </div>
                </IonItem>
                <IonItem color="light" lines="none">
                  <div className="item-container">
                    <div className="input-container">
                      <input id="orientation" className="input" type="text" placeholder="" value={unitPlate} onChange={e => {setUnitPlate(e.target?.value)}}/>
                      <p className="placeholder">Plate</p>
                    </div>
                  </div>
                </IonItem>
              </IonItemGroup>
            </div>
          </IonItem>
          
          </div>
        </div>
          <IonItem lines="none" color="white">
            <div className='item-container'>
              <button className="western-button submit" disabled={submitDisabled}  onClick={e => {submitReport()}}>SUBMIT REPORT</button>
            </div>
          </IonItem>
      </IonContent>
 
    </IonPage>
  );
};

export default GuardDog;

