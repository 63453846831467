import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import ContactCard from './ContactCard';
import {ApiService} from "../api/ApiService";

const KeyContacts: React.FC = () => {

  const [contacts, setContacts] = useState<any>({});

  useEffect(() => {
    ApiService.getContacts("keyContacts", {category: 'keyContacts'}).then(it => {
      const contacts: any = {};
      it.forEach(contact => {
        contacts[contact.subcategory] = (contacts[contact.subcategory] || []).concat(contact);
      });
      setContacts(contacts);
    });
  }, []);

  //const [contactDropdown, setContactDropdown] = useState(false);
  function collapseContent(id:string){
    const collapseSelect = document.getElementById(id);
    if (collapseSelect != null){
      collapseSelect.classList.toggle("dropdown-list");
    }
  }

  return (
    <IonPage>
 <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Key Contacts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
          <div style={{marginBottom: "9vh"}}>
            {Object.keys(contacts).map(key => {
              let element: any = contacts[key]
              let safetyCard = <div>
                {element.map((val: any, index: number) => {
                  console.log(val)
                  let content = <IonItem lines="none" key={index}><ContactCard contact={val}/></IonItem>
                  return content
                })}</div>        
            return safetyCard 
              })}
          </div>
          
      </IonContent>
    </IonPage>
  );
};

export default KeyContacts;
