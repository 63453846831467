import React, {useEffect, useState} from 'react';
import {ApiService} from "../api/ApiService";
import {DriverManager} from "../api/model/DriverManager";
import {IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonSearchbar, IonTitle, IonToolbar} from "@ionic/react";
import DmCard from "../components/DmCard";

const DmSelect = () => {

    const [driverManagers, setDriverManagers] = useState<DriverManager[]>([]);
    const [filteredManagers, setFilteredManagers] = useState<DriverManager[]>([]);

    useEffect(() => {
        ApiService.getDriverManagers("DmSelect").then(it => {
            it.sort((a, b) => a.dmName?.localeCompare(b.dmName) || a.username?.localeCompare(b.username));
            console.log("it", it);
            parseManagers(it);
        });
    }, []);

    function parseManagers(driverArray: DriverManager[]) {
        let tempArray: DriverManager[] = [];
        driverArray.forEach(element => {
            if (/[@]/.test(element.username) && element.dmCode) {
                tempArray.push(element)
            }
        });
        setDriverManagers(tempArray);
        setFilteredManagers(tempArray);
    }

    function filterDriverManagers(searchText: string): void {
        if (searchText?.length < 3) {
            setFilteredManagers(driverManagers);
            return;
        }
        const normalizedText = searchText.toLowerCase();

        setFilteredManagers(driverManagers.filter(it => it.username?.toLowerCase().includes(normalizedText) || it.dmName?.toLowerCase().includes(normalizedText)));
    }

    return <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/Settings" text=""/>
                </IonButtons>
                <IonTitle>Driver managers</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <div style={{marginBottom: "9vh"}}>
                <IonSearchbar mode={'ios'} disabled={!driverManagers.length} color="light" placeholder="Search..." animated={true} debounce={150}
                              onIonChange={(e: any) => filterDriverManagers(e.detail.value)}></IonSearchbar>
                {filteredManagers?.length ?
                    filteredManagers.map((it) => <DmCard model={it} key={it.id}></DmCard>) :
                    <p className="text-center color-black">No driver managers found!</p>
                }
            </div>
        </IonContent>
    </IonPage>
}
export default DmSelect;
