import React, {useEffect} from 'react';
import {App, AppState} from '@capacitor/app';
import {IonApp} from '@ionic/react';
import Login from './pages/Login';
import Main from './pages/Main';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/bootstrap-grid.css';
import './theme/bootstrap-utilities.css';

import './pages/index.css'
import AppToast from "./components/AppToast";
import { initializeApp } from 'firebase/app';
import {UserState, userStore} from "./api/AuthService";
import {PushNotificationsService} from "./api/PushNotificationsService";
import AppLoading from "./components/AppLoading";
import { APIProvider } from '@vis.gl/react-google-maps';

const firebaseConfig = {
    apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
    authDomain: "western-express-5002c.firebaseapp.com",
    databaseURL: "https://western-express-5002c.firebaseio.com",
    projectId: "western-express-5002c",
    storageBucket: "western-express-5002c.appspot.com",
    messagingSenderId: "799211811572",
    appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
    measurementId: "G-V5MNJV768B"
}
  
const app = initializeApp(firebaseConfig)

const Application: React.FC = () => {

    const {initialized, isLoggedIn, fetchUserData} = userStore((state:UserState) => ({initialized: state.initialized, isLoggedIn: state.isLoggedIn, fetchUserData: state.fetchUserData}));

    App.addListener('appStateChange', (state: AppState) => {
        console.log('App state changed. Is active?', state.isActive, window.screen.orientation.type);
    });
    
    PushNotificationsService.registerAfterAppLoad();

      

    useEffect(() => {
        if (!initialized) {
            fetchUserData();
        }
    }, [initialized]);

    return (
        <IonApp style={{display: 'flex'}}>
            {initialized ? isLoggedIn ? 
            <APIProvider apiKey={'AIzaSyBjCnqFyeQ7t8E6OraYXW4W-eSXtBgtWxs'} onLoad={() => console.log('Maps API has loaded.')}>
                <Main/> 
            </APIProvider>
            : <Login/> : <></>}
            <AppToast></AppToast>
            <AppLoading></AppLoading>
        </IonApp>
    )

}
export default Application;

