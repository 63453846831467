import {Capacitor} from "@capacitor/core";
import {PushNotifications} from "@capacitor/push-notifications";
import {ApiService} from "./ApiService";
import { initializeApp  } from "firebase/app"
import {isPlatform} from "@ionic/react";

export class PushNotificationsService {
    
    static eventArray: any = [];
    
    static firebaseConfig = {
        apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
        authDomain: "western-express-5002c.firebaseapp.com",
        databaseURL: "https://western-express-5002c.firebaseio.com",
        projectId: "western-express-5002c",
        storageBucket: "western-express-5002c.appspot.com",
        messagingSenderId: "799211811572",
        appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
        measurementId: "G-V5MNJV768B"
      }
      
    static app = initializeApp(PushNotificationsService.firebaseConfig)
      
    public static async registerAfterAppLogin(userId: string) {
        if (Capacitor.isPluginAvailable('PushNotifications')) {
            if (JSON.parse(localStorage.getItem('pushNotificationPermission')!) === null) {
                const permissionGranted = PushNotifications.requestPermissions();
                if ((await permissionGranted) && JSON.parse(localStorage.getItem('pushNotificationPermission')!) === null) {
                    PushNotifications.register();
                } else {
                    localStorage.setItem('refusalDate', JSON.stringify(new Date()));
                }
            } else {
                console.log("PushNotifications register");
                PushNotifications.register();
            }

            PushNotifications.addListener('registration',
                async (token) => {
                    localStorage.setItem('pushNotificationPermission', token.value);
                    await ApiService.registerDeviceToken("pushNotificationsService", {platform: isPlatform('ios') ? 'ios' : 'android', userId});
                }
            );

            PushNotifications.addListener('registrationError',
                (error: any) => {
                    console.log('Error on registration: ' + JSON.stringify(error))
                    alert('Error on registration: ' + JSON.stringify(error));
                }
            );
        }
    }

    public static registerAfterAppLoad() {
        if (Capacitor.isPluginAvailable('PushNotifications') && this.eventArray.length <= 1) {
            this.eventArray.pop();
            PushNotifications.removeAllListeners();
            this.eventArray.push('pushNotificationReceived')
            for (let index = 0; index < this.eventArray.length; index++) {
                PushNotifications.addListener('pushNotificationReceived', (notification) => {
                        // alert(notification.body);
                        console.log("pushNotificationReceived", notification.body, notification);
                    }
                );
            }
        }
    }


}
