import { IonButton, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonItemGroup, IonRow, IonToggle } from "@ionic/react";
import React, { useState } from "react";
import { chevronForwardCircle, checkmarkCircle, warning, close, caretDownCircle, checkmarkDoneCircle, sunny  } from 'ionicons/icons';


const OrientationDriverEvalItem: React.FC<{category: string, description: string, status: boolean, value: number }> = ({category, description, status, value}) => {
    

    const color = value < 0 ? "danger" : "success"
    return (
        <IonGrid>
            <IonRow>
                <IonCol size="6">
                    <p>{description}</p>
                </IonCol>
                <IonCol size="3">
                    <p>{status ? "YES" : "NO"} <span style={{ color: "red"}}>{value < 0 ? value : ""}</span></p>
                   
                </IonCol>
                <IonCol size="3">
                {/* <div style={{ backgroundColor: "white" }}>
                <label className="switch">
                    <input type="checkbox" id="togBtn" />
                    <div className="slider round">
                <span className="yesNo">NO   {value}   YES</span>
                    
             
                    </div>
                </label>
                </div> */}
                    <IonToggle checked={status} color={color}></IonToggle>
                </IonCol>

                
            </IonRow>
{/*            
            {status ? <IonIcon slot="end" color="primary" icon={checkmarkCircle}></IonIcon> : ''} */}
        </IonGrid>
    )
}

export default OrientationDriverEvalItem