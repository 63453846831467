import React, {useState} from 'react';
import {DriverManager} from "../api/model/DriverManager";
import {IonIcon, IonItem, IonSearchbar} from "@ionic/react";
import {chevronDownOutline, chevronUpOutline, logInOutline} from "ionicons/icons";
import '../theme/dmCard.css';
import {DriverInfo} from "../api/model/Drivers";
import {ApiService} from "../api/ApiService";

const DmCard = (props: { model: DriverManager }) => {

    const [open, setOpen] = useState<boolean>(false);
    const [drivers, setDrivers] = useState<DriverInfo[]>();
    const [filteredDrivers, setFilteredDrivers] = useState<DriverInfo[]>();

    function toggleCard() {
        if (!open && !drivers) {
            loadManagerDrivers();
        } else {
            setOpen(!open);
        }
    }

    function loadManagerDrivers(): void {
        ApiService.getManagerDrivers("dmCard", props.model.dmCode).then(it => {
            const parsedDrivers = Object.keys(it).sort().map(key => {
                return {driverCode: key, name: it[key].name, phone: it[key].phone, unit: it[key].unit};
            })
            parsedDrivers.sort((a, b) => !a.unit ? 1 : !b.unit ? -1 : 0);
            setDrivers(parsedDrivers);
            setFilteredDrivers(parsedDrivers);
            setOpen(true);
        });
    }

    function filterDriverManagers(searchText: string): void {
        if (searchText?.length < 3) {
            setFilteredDrivers(drivers);
            return;
        }
        const normalizedText = searchText.toLowerCase();

        setFilteredDrivers(drivers?.filter(it => it.driverCode?.toLowerCase().includes(normalizedText) || it.name?.toLowerCase().includes(normalizedText) || it.unit?.includes(normalizedText)));
    }

    return (
        <IonItem button mode={'ios'} detail={false} lines={'none'} color={'light'} className="dm-card" onClick={() => toggleCard()}>
            <div className="dm-card-content">
                <p className="dm-card-header">{props.model.username}</p>
                {props.model.dmName && <span className="dm-card-flavor">{props.model.dmName}</span>}
                <p className="dm-card-sub">Email : {props.model.email || '-'}</p>
                <p className="dm-card-sub">Phone #: {props.model.dmPhone || '-'}</p>
                {/* <p className="dm-card-sub">Drivers: {props.model.driverCount}</p> */}
                {open &&
                    <div onClick={(e) => e.stopPropagation()}>
                        {drivers?.length ? (
                            <>
                                <IonSearchbar color="light" placeholder="Search..." animated={true} debounce={150} onIonChange={(e: any) => filterDriverManagers(e.detail.value)}></IonSearchbar>
                                {filteredDrivers?.map(it =>
                                    <div color={'light'} key={it.driverCode} className="dm-driver-card">
                                        <div className="dm-driver-card-content">
                                            <p className="dm-card-header">{it.driverCode}</p>
                                            {it.name && <span className="dm-card-flavor">{it.name}</span>}
                                            <p className="dm-card-sub">Phone #: {it.phone || '-'}</p>
                                            <p className="dm-card-sub">Unit: {!!it.unit ? it.unit : <span className="color-danger">driver has no unit assigned</span>}</p>
                                        </div>
                                        <button className="app-btn app-btn-primary" disabled={!it.unit} onClick={() => ApiService.loginAsDriver("dmCard", {driverCode: it.driverCode})}>
                                            <IonIcon className="pe-2" icon={logInOutline}></IonIcon>
                                            Login as driver
                                        </button>
                                    </div>)}</>
                        ) : <p className="text-center">No drivers found!</p>}
                    </div>
                }
            </div>
            <IonIcon icon={open ? chevronUpOutline : chevronDownOutline} slot={'end'} className={'top-icon'}></IonIcon>
        </IonItem>
    )
}
export default DmCard;
