import React, {useState} from "react";
import {IonItem, IonItemGroup, IonModal} from "@ionic/react";
import {userStore} from "../../api/AuthService";

const TrainerAdvantageProgramDialog = (props: { onAccept: () => void }) => {

    const [modalOpen, setModalOpen] = useState(true);

    const accept = () => {
        props.onAccept();
        setModalOpen(false);
    }

    return <IonModal isOpen={modalOpen} backdropDismiss={false}>
        <IonItemGroup>
            <IonItem color="light" lines="none">
                <div className="item-container">
                    <h1>Congratulations!</h1>
                </div>
            </IonItem>
            <IonItem lines="none" color="light">
                <div style={{minHeight: '462px'}}>
                    <p>{userStore.getState().driver.name}, congratulations on being approved to be a driver trainer. You will need to complete the online training that has been assigned and then
                        complete an in person class.</p>
                    <p>Please ensure when you come in for the class, your truck is clean and ready for a new trainee.</p>
                    <br/>
                    <p>Online Classes: <a href="https://westernexpress.infinit-i.net/#/Login" target="_blank" rel="noopener noreferrer">https://westernexpress.infinit-i.net/#/Login</a></p>
                    <p>Username: {userStore.getState().driver.driverCode}</p>
                    <p>Password: western1</p>
                </div>
            </IonItem>
            <IonItem lines="none" color="light">
                <div className="item-container">
                    <button className="app-btn app-btn-primary w-100 text-center d-block" onClick={() => accept()}>Accept</button>
                </div>
            </IonItem>
        </IonItemGroup>
    </IonModal>
}
export default TrainerAdvantageProgramDialog;
