import { IonContent, IonFooter, IonHeader, IonItem, IonPage, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import Contacts from "./Contacts";

import Nav from "./Nav";
import Search from "./Search";
import Toolbar from "./Toolbar";
import {Link, useHistory} from "react-router-dom";
import { mailOpen } from "ionicons/icons";

const Support = () => {

    const history = useHistory();

    const [isSearching, setIsSearching] = useState(false)
    function handleSearch(value: string) {
        console.log(value)
    }
    function handleMode (value: boolean) {
        // setIsSearching(value)
        history.push('/home?search=true');
    }
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <Toolbar />
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen style={{"--background": "#f4f5f8"}}>
                {isSearching ? <Search onChange={handleSearch} featured={'Lets take a look'}/> : null}
                <IonItem lines="none" color="light"><div className="item-container"><h3><strong style={{ margin: '0 8px'}}>Support</strong></h3></div></IonItem> 
                <Contacts/>
                <IonItem lines="none" color="light">
                    <div className="item-container">
                        <Link to="/servicelocations">
                                <p>Looking for a terminal?</p>
                        </Link>
                    </div>
                </IonItem>

            </IonContent>
            <IonFooter>
                <Nav onSearch={handleMode}/>
            </IonFooter>
        </IonPage>
    )
}

export default Support
