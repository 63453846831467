import {isPlatform} from "@ionic/react";

export class PlatformUtils {

    public static getCurrentPlatform(driverManager?: boolean): string {
        const platformPrefix = driverManager ? 'dm/' : '';

        if (isPlatform('ios')) {
            return platformPrefix + 'ios';
        }
        if (isPlatform('android')) {
            return platformPrefix + 'android';
        }
        return platformPrefix + 'other';
    }

    public static getStoreLink(): string {
        if (isPlatform('android')) {
            return 'market://details?id=com.pegasustranstech.transflonowplus';
        }
        if (isPlatform('desktop') || isPlatform('iphone') || isPlatform('ipad')) {
            return 'https://apple.co/30q9xpS';
        }
        return '';
    }

}
