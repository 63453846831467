import React, {useEffect, useState} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonNote,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import RoadTest from './RoadTest';
import {chevronDown, chevronForward, chevronUp} from 'ionicons/icons';
import DailyReports, {Report, ReportReference} from './DailyReports';
import AnimateHeight from 'react-animate-height';
import newIcon from "../imgs/new-trainee.png"
import ReportHistory from './ReportHistory';
import DailyReport from './DailyReport';
import {Link} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {Trainee} from "../api/model/Trainee";
import {TrainingCourse} from "../api/model/TrainingCourses";
import {toastStore} from "../components/AppToast";
import {userStore} from "../api/AuthService";

interface Course {
  enabled: number;
  id: number;
  title: string;
  total_backing_events: number;
  total_days: number;
}

const TrainingDay: React.FC = () => {

  const now = new Date()

  const [showNewReport, setShowNewReport] = useState(false)
  const [traineeData, setTraineeData] = useState<Trainee>()


  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  //Log - *change* DELETE:
  const [profileModal, setProfileModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [reports, setReports] = useState<ReportReference[]>([]);
  const [report, setReport] = useState<Report>({
    trainerCode: '',
    roadTest: '',
    courseId: 0,
    courseDay: 0,
    backingScore: 0,
    turningScore: 0,
    roadHabitsScore: 0,
    cityScore: 0,
    highwayScore: 0,
    tabletScore: 0,
    hosScore: 0,
    defensiveScore: 0,
    overallScore: 0,
    dmCode: ''
  })

  const [showRoadTest, setShowRoadTest] = useState(false);
  const [reportId, setReportId] = useState(0);
  const [traineeCode, setTraineeCode] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);
  const [reportTimer, setReportTimer] = useState<string>('Not Ready');
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [hasTrainee, setHasTrainee] = useState(false);
  const [activity, setActivity] = useState<string>('');
  const [safety, setSafety] = useState<string>('');
  const [submittedTest, setSubmittedTest] = useState(0);
  const [trainingDate, setTrainingDate] = useState(now.toJSON());
  const [hasRoadTest, setHasRoadTest] = useState(true);
  const [noTrainees, setNoTrainees] = useState(false);
  const [trainees, setTrainees] = useState<Trainee[]>([]);
  const [course, setCourse] = useState<Course>({
    enabled: 0,
    id: 0,
    title: '',
    total_backing_events: 0,
    total_days: 0
  });
  const [changingCourse, setChangingCourse] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState<number>();
  const [traineeAccident, setTraineeAccident] = useState('');
  const [traineeReady, setTraineeReady] = useState('');
  const [trainee, setTrainee] = useState<Trainee>({
    currentCourseDay: 0,
    traineeCode: '',
    traineeName: '',
    currentCourseId: 0,
    dateCreated: '',
    drivingHours: 0,
    dmCode: '',
    passed: 0,
    trainerCode: '',
    trainerName: '',
    released: 0,
    seatedDate: ''
  });
  const [ backingScore, setBackingScore ] = useState(0);
  const [ turningScore, setTurningScore ] = useState(0);
  const [ roadHabitsScore , setRoadHabitsScore ] = useState(0);
  const [ cityScore, setCityScore] = useState(0);
  const [ highwayScore, setHighwayScore] = useState(0);
  const [ tabletScore, setTabletScore ] = useState(0);
  const [ hosScore, setHOSScore] = useState(0);
  const [ defensiveScore, setDefensiveScore ] = useState(0);
  const [securedLoad, setSecuredLoad] = useState('yes');
  const [height1, setHeight1] = useState(false);
  const [height2, setHeight2] = useState(false);
  const [height3, setHeight3] = useState(false);
  const [height4, setHeight4] = useState(false);
  const [height5, setHeight5] = useState(false);
  const [height6, setHeight6] = useState(false);
  const [height7, setHeight7] = useState(false);
  const [height8, setHeight8] = useState(false);

  useEffect(() => {
    ApiService.getTrainees("trainingDay", {}).then(it => {
      setNoTrainees(it.length === 0);
      setTrainees(it.filter(trainee => trainee.passed === null));
    });
  }, []);

  function uniqueKey(unique: string) {
    const key = unique;
    return key;
  }
  
  function collapseContent(id:string, display:boolean){
                
    const collapseSelect = document.getElementById(id);
    if (collapseSelect != null && display === false){
      collapseSelect.classList.toggle("dropdown-list");
      display = true;
    } else if (collapseSelect != null && display === true){
      collapseSelect.style.setProperty('display', 'inline');
    } else {
      display = true;
    }
  }

  function highlightContent(id:string, target:string, toggle:string){
    const remove = document.getElementById(toggle)
    const select = document.getElementById(id)
    const button_GO = document.getElementById(target);
    if (remove != null){
      remove.style.setProperty('--ion-color-primary', '666666')
    }
    if (select != null){
      select.style.setProperty('--ion-color-primary', '#617130') ;    
    }

    if (button_GO != null){
      button_GO.style.setProperty('--ion-color-primary','#99CC00');
    }
  }

  function getCourseInfo() {
    ApiService.getTrainingCourses("trainingDay").then(it => {
      parseCourses(it);
    });
  }

  function parseCourses(courseArray: TrainingCourse[]) {
    let tempArray: Course[] = [];
    courseArray.forEach((element: { enabled: number; id: number; title: string; total_backing_events: number; total_days: number; }) => {
      if (element.enabled === 1) {
        let obj: any = element;
        tempArray.push(obj);
      }
      if (element.id === trainee.currentCourseId) {
        setCourse(element);
        console.log(element, trainee);
      } else {
        setCourse({ enabled: 0, id: 0, title: "No course assigned", total_backing_events: 0, total_days: 0 });
      }
    });
    console.log(tempArray);
    setCourses(tempArray);
  }

  const handleSubmit = async () => {
    if (!scoresCheck() && !traineeAccident) {
      toastStore.getState().setToast('Please fill out the report completely');
    } else {
      submitReport();
    }
  }

  const scoresCheck = () => {
    if (backingScore == 0 ||
       turningScore == 0 ||
       tabletScore == 0 ||
       roadHabitsScore == 0 ||
       hosScore == 0 ||
       highwayScore == 0 ||
       cityScore == 0 ||
       defensiveScore == 0 ||
       traineeAccident == '' ||
       traineeReady == ''
       ) {
        return false;
    } else {
        return true;
    }
  }

  async function submitCourseChange() {
    ApiService.assignTraineeCourse("trainingDay", {traineeCode: trainee.traineeCode, courseId: selectedCourseId!}).then(() => {
      setShowModal2(true);
      setShowModal3(false);
    });
  }

  async function submitReport() {
    ApiService.submitTrainingReport2("trainingDay", {
      dmCode: userStore.getState().driver?.dmCode || trainee.dmCode!,
      traineeDrove: 1,
      traineeCode: trainee.traineeCode,
      courseId: trainee.currentCourseId,
      courseDay: trainee.currentCourseDay,
      reportData: JSON.stringify({
        backingScore: backingScore,
        turningScore: turningScore,
        tabletScore: tabletScore,
        roadHabitsScore: roadHabitsScore,
        hosScore: hosScore,
        highwayScore: highwayScore,
        cityScore: cityScore,
        defensiveScore: defensiveScore
      }),
      overallScore: backingScore + turningScore + tabletScore + roadHabitsScore + hosScore + highwayScore + cityScore + defensiveScore,
      traineeAccident: traineeAccident ? 1 : 0,
      traineeReady: traineeReady ? 1 : 0,
      roadTest: submittedTest,
      reportId: reportId,
      loadSecurement: securedLoad === 'yes' ? 1 : 0,
    }).then(() => {
      toastStore.getState().setSuccessToast('Your report has been submitted!');
      setShowModal(true);
      setShowModal1(false);
    }).finally(() => {
      setShowModal1(false);
    });
  }

  const testSubmitted = () => {
    setSubmittedTest(1);
  }

  const handleCourseChange = () => {
    submitCourseChange();
  }

  const resetForm = () => {
    setShowRoadTest(false);
  }

  const resetScores = () => {
    setBackingScore(0);
    setTurningScore(0);
    setRoadHabitsScore(0);
    setHOSScore(0);
    setTabletScore(0);
    setCityScore(0);
    setHighwayScore(0);
    setDefensiveScore(0);
  }

  return (
    <IonPage>
    <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
        <IonBackButton defaultHref="/Home"  text=""/>
        </IonButtons>
       
        <IonTitle>Reports</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color='light'>
      <IonModal isOpen={showNewReport}>
        <IonHeader>
          <IonToolbar color="primary">
          <IonButton slot="start" style={{padding: 0}}><p style={{fontFamily: "Montserrat, sans-seriff"}} onClick={() => setShowNewReport(false)}>Back</p></IonButton>
            {/* <IonItem slot="start" color="dark" lines="none"><p onClick={() => setShowNewReport(false)} style={{}}>Close</p></IonItem> */}
            <IonTitle>New Report</IonTitle>
          </IonToolbar>
        </IonHeader>
        <DailyReport traineeData={traineeData}/>
      </IonModal>
      {/* <IonButton id="western-button" disabled={trainee.currentCourseId === 0} onClick={e => {setShowRoadTest(true)}}></IonButton> */}
      
      { hasTrainee ?
      <IonButton id="western-button" style={{marginTop: "5vh"}} onClick={e => setHasTrainee(false)}>⌫ Trainees</IonButton> :
      null } 

      {/* { hasRoadTest ? <IonButton id="western-button" onClick={e => setHasRoadTest(true)}>Road Test ⌦</IonButton>: 
      null } */}
        <IonModal isOpen={showModal} cssClass='my-custom-class'>
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>Thank You</IonCardSubtitle>
            <IonCardTitle>Your report has been submitted.</IonCardTitle>
          </IonCardHeader>
        </IonCard>
        <div>
          <IonButton id="western-button" style={{"--background": "#99cc00", background: "#99cc00", color: "black"}} expand="block" href="/Home" >Have Feedback?</IonButton>
          <IonButton id="western-button" expand="block" href="/Home" onClick={ e => { setShowModal(false); setShowModal1(false);}}>Done</IonButton>
        </div>
        </IonModal>

        <IonModal isOpen={showModal1} cssClass='my-custom-class'>
          <IonCard>
          <IonCardHeader>
              <IonCardSubtitle>Confirm?</IonCardSubtitle>
              <IonCardTitle>Submit Report for Trainee: {trainee.traineeCode}?</IonCardTitle>
            </IonCardHeader>
          <IonButton id="western-button" expand="block" onClick={ e => { handleSubmit();}}>Confirm</IonButton>
          <IonButton id="western-button" expand="block" onClick={ e => { setShowModal1(false)}}>Cancel</IonButton>
          </IonCard>
        </IonModal>

        <IonModal isOpen={showModal2} cssClass='my-custom-class'>
          <IonCard>
          <IonCardHeader>
              <IonCardSubtitle>Thank You</IonCardSubtitle>
              <IonCardTitle>Course change has been submitted.</IonCardTitle>
            </IonCardHeader>
          </IonCard>    
          <IonButton id="western-button" expand="block" onClick={ e => { setShowModal2(false); setHasTrainee(false)}}>Done</IonButton>
        </IonModal>

        <IonModal isOpen={showModal3} cssClass='my-custom-class'>
          <IonCard>
          <IonCardHeader>
              <IonCardSubtitle>Confirm?</IonCardSubtitle>
              <IonCardTitle>Change course for trainee: {trainee.traineeCode}?</IonCardTitle>
            </IonCardHeader>
          <IonButton id="western-button" expand="block" onClick={ e => { handleCourseChange();}}>Confirm</IonButton>
          <IonButton id="western-button" expand="block" onClick={ e => { setShowModal3(false); setShowCourseModal(true); setChangingCourse(false)}}>Cancel</IonButton>
          </IonCard>
        </IonModal>

        <IonModal isOpen={showCourseModal} cssClass='my-custom-class'>
          
        {!changingCourse ? <IonContent>
            <IonItemDivider color="primary" id="modalheader">
              <IonLabel className="ion-text-wrap" style={{width: '100%'}}><h1 style={{textAlign:"center"}}>Course Info</h1></IonLabel>
            </IonItemDivider>
            
            <IonButton id="western-button" key={uniqueKey(trainee.traineeCode)} onClick={ () => {setShowCourseModal(false)}}>[x] Close: </IonButton>
            <IonList>
              <IonItem>
                <IonLabel>Course Name</IonLabel>
                <IonNote slot="end" color="black">{course!.title}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Backing Events</IonLabel>
                <IonNote slot="end" color="black">{course!.total_backing_events}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Days required</IonLabel>
                <IonNote slot="end" color="black">{course!.total_days}</IonNote>
              </IonItem>
            </IonList>
            <IonLabel className="ion-text-wrap"><h2 style={{textAlign:"center", padding:"7px"}}>Comments</h2></IonLabel>
            {/* <IonButton id="western-button" expand="block" onClick={e => {setChangingCourse(true)}}>Change Course</IonButton>
            <IonButton id="western-button" expand="block" onClick={e => {setShowCourseModal(false); setChangingCourse(false)}}>Close</IonButton> */}
          </IonContent> :
          <IonContent>
          <IonItemDivider color="primary" id="modalheader">
            <IonLabel className="ion-text-wrap"><h1 style={{textAlign:"center"}}>Change Course</h1></IonLabel>
          </IonItemDivider>
            <IonLabel position="floating" style={{width: "100%", marginLeft: "10%"}}>Course</IonLabel>
          <IonItem>
            <IonSelect  style={{width: '75%', marginLeft: "15%"}} value={selectedCourseId} okText="Okay" cancelText="Cancel" onIonChange={e => {setSelectedCourseId(e.detail.value)}}>
              {courses.map(function(element) {
              return  <IonSelectOption value={element.id}>{element.title}</IonSelectOption>
              })}
            </IonSelect>
          </IonItem>
          <IonLabel className="ion-text-wrap"><h2 style={{textAlign:"center", padding:"7px"}}>Comments</h2></IonLabel>
          <IonButton id="western-button" expand="block" onClick={e => {setShowModal3(true); setShowCourseModal(false); setChangingCourse(false)}}>Change</IonButton>
          <IonButton id="western-button" expand="block" onClick={e => {setChangingCourse(false); setChangingCourse(false)}}>Cancel</IonButton>
        </IonContent> }
        </IonModal>

      { hasTrainee ? <> { !showRoadTest ? <> { trainee.currentCourseId !== 0 ?
        <IonList>
          <IonItemGroup>
            <IonItem>
              <IonLabel className="ion-text-wrap"><h1 className="c-c">Activity Prompt</h1></IonLabel>
            </IonItem>
            <IonItemDivider className="form-header">
              <IonLabel style={{padding: '4px'}} className="ion-text-wrap">                  
                <p className="label-text" style={{fontSize: '16px', color: "#dddddd", lineHeight: "22px"}}>{activity}</p>
              </IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel className="ion-text-wrap"><h1 className="c-c">Safety Prompt sdfg</h1></IonLabel>
            </IonItem>
            <IonItemDivider className="form-header">
              <IonLabel style={{padding: '4px'}} className="ion-text-wrap">                  
                <p className="label-text" style={{fontSize: '16px', color: "#dddddd", lineHeight: "22px"}}>{safety}</p>
              </IonLabel>
            </IonItemDivider>
            <IonButton id="western-button" expand="block" onClick={e => {setShowCourseModal(true); getCourseInfo();}}>Course Info</IonButton>
            <IonItemDivider className="form-header">
              <IonLabel style={{padding: '4px'}} className="ion-text-wrap">                  
                <h3 className="label-text" style={{fontSize: '25px'}}>Road Test: {hasRoadTest ? <span style={{color: '#6e9c00', textAlign: 'center'}}>Recommended</span> : <span style={{color: '#cf3c4f', textAlign: 'center'}}>No</span>}</h3>
              </IonLabel>
            </IonItemDivider>
              <IonButton id="western-button" expand="block" disabled={trainee.currentCourseId === 0} onClick={e => {setShowRoadTest(true)}}>Road Test Form</IonButton>
              <div className="divider-divider"></div>
            </IonItemGroup>
          <div className="fake-label" style={{textAlign: 'center', margin: '18px 0 18px 0'}}>  
            <label className="label-left" style={{margin: "10px", fontSize: "25px"}}>Training Date: {trainingDate.replace('T', ' ').replace('Z', ' ').slice(0, 10).substr(5) + "-" + trainingDate.replace('T', ' ').replace('Z', ' ').slice(0, 10).substr(0, 4)}</label>
          </div>
          <IonItemDivider className='training-header'> 
            <h3>Report Cooldown Timer:<br /> <span style={reportTimer === 'Not Ready' || trainee.currentCourseId === 0 ? {color: '#cf3c4f', textAlign: 'center'} : {color: '#6e9c00', textAlign: 'center'}}>{reportTimer}</span></h3>
          </IonItemDivider>

          <IonItem>
            <div style={{textAlign: "start", fontSize: "16px", fontWeight: "600", margin: "auto"}}>Trainee involved in accident?</div>
            <IonSelect style={{width: "70px"}} value={traineeAccident} onIonChange={e => {setTraineeAccident(e.detail.value); traineeAccident == 'yes' ? resetScores() : console.log('no change');}}>
              <IonSelectOption value={"yes"}>Yes</IonSelectOption>
              <IonSelectOption value={"no"}>No</IonSelectOption>
            </IonSelect>
          </IonItem>

          {/*[...] BACKING*/}
          <IonButton disabled={traineeAccident == 'yes'} className="training-btn" strong fill="solid" color="black" onClick={ e => setHeight1(!height1) } aria-expanded={ height1 !== false }><div className="training-text">Backing!</div><div className="training-text-back" style={backingScore !== 0 ? {color: '#95d201'} : {color: 'white'} }>{backingScore !== 0 ? "Complete" : "Not Completed" }</div><IonIcon style={{ marginLeft: "5px", fontSize: "10vw"}} icon={height1 ? chevronDown : chevronUp}></IonIcon></IonButton>
          <AnimateHeight style={{ width: '100vw', overflowY: 'scroll'}} duration={500} height={ height1 === true ? 'auto' : 0 } id="loadSection">
            <IonCardHeader style={{backgroundColor: "transparent"}}>
              <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>Rate Trainee on their <b>backing</b> performance based on the following.</span><br/><br/><span style={{fontSize: "12px", lineHeight: '20px'}}>- GOAL: Get Out And Look<br/>- Blows horn before starting the back<br/>- Mirror Use<br/>- 45, 90 and Straight Back</span></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem style={{width:'100%', padding:'10px'}}>
                  <IonLabel className='ion-text-wrap'>Select a Score</IonLabel>
                  <IonSelect disabled={traineeAccident == 'yes'} value={backingScore} onIonChange={e => {setBackingScore(e.detail.value); setHeight1(!height1)}}>
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <div style={{fontSize: "12px", padding: "15px", color: "black"}}>
                    <span>1.	Trainee is unable to complete the movement successfully. Cannot perform a 45, 90, or straight back without step-by-step instruction from the trainer.<br/><br/></span>
                    <span>2.	Trainee can perform a backing event; however, not in a timely manner. Takes the trainee more than 10 minutes with multiple pull ups. Trainee does not do one or any of the following: GOAL (Get Out and Look), use mirrors, honk horn before backing.<br/><br/></span>
                    <span>3.	Trainee can perform a backing event in under 10 minutes with multiple pull ups. Trainee does not do one of the following: GOAL (Get Out and Look), use mirrors, honk horn before backing.<br/><br/></span>
                    <span>4.	Trainee can perform a backing event in under 7 minutes with 5 or less pull ups. Trainee does all of the following: GOAL (Get Out and Look), use mirrors, honk horn before backing.<br/><br/></span>
                    <span>5.	Trainee can perform a backing event in under 5 minutes with 3 or less pull ups. Trainee does all of the following: GOAL (Get Out and Look), use mirrors, honk horn before backing.</span>
                  </div>
            </IonCardContent>
          </AnimateHeight>


          {/*[...] TURNING*/}
          <IonButton disabled={traineeAccident == 'yes'} className="training-btn" strong fill="solid" color="black" onClick={ e => setHeight2(!height2) } aria-expanded={ height2 !== false }><div className="training-text">Turning</div><div className="training-text-back" style={turningScore !== 0 ? {color: '#95d201'} : {color: 'white'} }>{turningScore !== 0 ? "Complete" : "Not Completed" }</div><IonIcon style={{ marginLeft: "5px", fontSize: "10vw"}} icon={height2 ? chevronDown : chevronUp}></IonIcon></IonButton>
          <AnimateHeight style={{ width: '100vw', overflowY: 'scroll'}} duration={500} height={ height2 === true ? 'auto' : 0 } id="loadSection">
            <IonCardHeader style={{backgroundColor: "transparent"}}>
              <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>Rate Trainee on their <b>turning</b> performance based on the following.</span><br/><br/><span style={{fontSize: "12px", lineHeight: '20px'}}>- Set up and execution of a turn<br/>- Uses mirrors to clear trailer tires</span></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem style={{width:'100%', padding:'10px'}}>
                  <IonLabel className='ion-text-wrap'>Select a Score</IonLabel>
                  <IonSelect disabled={traineeAccident == 'yes'} value={turningScore} onIonChange={e => {setTurningScore(e.detail.value); setHeight2(!height2)}}>
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <div style={{fontSize: "12px", padding: "15px", color: "black"}}>
                    <span>1.	Trainee is unable to negotiate a turn without step-by-step instruction from the trainer. Trainee may have a close call during a turning event where tires come up on the curb or into the shoulder of the road. Trainee does not set up the turn properly or use mirrors to clear the trailer tires.<br/><br/></span>
                    <span>2.	Trainee can negotiate a turn, however; does not set up properly and may be curbing their tires. Also not turning into the proper lane at an intersection.<br/><br/></span>
                    <span>3.	Trainee can negotiate a turn, however; one of the following is an issue: does not set up properly, curbs tire or runs onto sidewalk, or is turning into an incorrect lane at an intersection.<br/><br/></span>
                    <span>4.	Trainee can negotiate a turn. Can set up properly, uses mirrors to clear trailer tires, and is turning into the proper lane; however, may have occasional issues when performing multiple turns in a day.<br/><br/></span>
                    <span>5.	Trainee can successfully negotiate a turn consistently. Has a proper set up, uses mirrors to clear trailer tires, and turns into the proper lane. Consistent performance throughout the day.</span>
                  </div>
            </IonCardContent>
          </AnimateHeight>
          
          {/*[...] ROAD_CONDITIONS*/}

          <IonButton disabled={traineeAccident == 'yes'} className="training-btn" strong fill="solid" color="black" onClick={ e => setHeight3(!height3) } aria-expanded={ height3 !== false }><div className="training-text">Driving Habits</div><div className="training-text-back" style={roadHabitsScore !== 0 ? {color: '#95d201'} : {color: 'white'} }>{roadHabitsScore !== 0 ? "Complete" : "Not Completed" }</div><IonIcon style={{ marginLeft: "5px", fontSize: "10vw"}} icon={height3 ? chevronDown : chevronUp}></IonIcon></IonButton>
          <AnimateHeight style={{ width: '100vw', overflowY: 'scroll'}} duration={500} height={ height3 === true ? 'auto' : 0 } id="loadSection">
            <IonCardHeader style={{backgroundColor: "transparent"}}>
              <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>Rate Trainee on their <b>driving habits for various road conditions</b> based on the following.</span><br/><br/><span style={{fontSize: "12px", lineHeight: '20px'}}>- Ramps and Curves<br/>- Downhill<br/> - Weather Conditions<br/>- Traffic Conditions<br/></span></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem style={{width:'100%', padding:'10px'}}>
                  <IonLabel className='ion-text-wrap'>Select a Score</IonLabel>
                  <IonSelect disabled={traineeAccident == 'yes'} value={roadHabitsScore} onIonChange={e => {setRoadHabitsScore(e.detail.value); setHeight3(!height3)}}>
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <div style={{fontSize: "12px", padding: "15px", color: "black"}}>
                    <span>1.	Trainee does not adjust driving habits for road or weather conditions. Driving too fast for conditions, does not control speed downhill or around ramps or curves, and is not adjusting to traffic conditions.<br/><br/></span>
                    <span>2.	Trainee is not consistent in adjusting driving habits for changing conditions throughout the day. May adjust occasionally, but not for all necessary changes is conditions.<br/><br/></span>
                    <span>3.	Trainee recognizes conditions where driving habits need to be adjusted but does not fully adjust driving habits. Example: trainee slows down but does not also increase following distance in inclement weather.<br/><br/></span>
                    <span>4.	Trainee recognizes and adjusts driving habits for conditions, however; may need to improve in one of the following areas (ramps and curves, controlling speed downhill, weather conditions, traffic conditions).<br/><br/></span>
                    <span>5.	Trainee always adjusts driving habits according to conditions. Trainee effectively recognizes and changes driving behavior for changes in the following ramps and curves, downhill, weather conditions, and traffic conditions.</span>
                  </div>
            </IonCardContent>
          </AnimateHeight>

          {/*[...] CITY*/}

          <IonButton disabled={traineeAccident == 'yes'} className="training-btn" strong fill="solid" color="black" onClick={ e => setHeight4(!height4) } aria-expanded={ height4 !== false }><div className="training-text">City Driving</div><div className="training-text-back" style={cityScore !== 0 ? {color: '#95d201'} : {color: 'white'} }>{cityScore !== 0 ? "Complete" : "Not Completed" }</div><IonIcon style={{ marginLeft: "5px", fontSize: "10vw"}} icon={height4 ? chevronDown : chevronUp}></IonIcon></IonButton>
          <AnimateHeight style={{ width: '100vw', overflowY: 'scroll'}} duration={500} height={ height4 === true ? 'auto' : 0 } id="loadSection">
            <IonCardHeader style={{backgroundColor: "transparent"}}>
              <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>Rate Trainee on their <b>city driving</b> based on the following.</span><br/><br/><span style={{fontSize: "12px", lineHeight: '20px'}}>- Turns<br/>- Signaling<br/> - Mirror Use</span></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem style={{width:'100%', padding:'10px'}}>
                  <IonLabel className='ion-text-wrap'>Select a Score</IonLabel>
                  <IonSelect disabled={traineeAccident == 'yes'} value={cityScore} onIonChange={e => {setCityScore(e.detail.value); setHeight4(!height4)}}>
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <div style={{fontSize: "12px", padding: "15px", color: "black"}}>
                    <span>1.	Trainee does not negotiate turns, intersections, does not signal, or use their mirrors to negotiate traffic. Trainee may have a near miss while in a city or industrial area.<br/><br/></span>
                    <span>2.	Trainee can drive in cities and industrial areas; however, does not negotiate intersections or turns properly, does not signal, and only occasionally uses their mirrors in changing lanes. Trainee may be waiting until the last chance to change lanes for an exit or get into the proper lane for an intersection.<br/><br/></span>
                    <span>3.	Trainee can drive in cities and industrial areas; however, needs to improve on more than one of the following areas: negotiating intersections and turns, signaling appropriately, or using their mirrors when changing lanes or negotiating turns.<br/><br/></span>
                    <span>4.	Trainee can drive in cities and industrial areas proficiently; however, needs to improve on one of the following areas: negotiating intersections and turns, signaling appropriately, or using their mirrors when changing lanes or negotiating turns.<br/><br/></span>
                    <span>5.	Trainee proficiently negotiates city and industrial areas. Trainee negotiates intersections and turns properly, signals in advance of movement, and always uses their mirrors when changing lanes or negotiating turns.</span>
                  </div>
            </IonCardContent>
          </AnimateHeight>

          {/*[...] HIGHWAY*/}

          <IonButton disabled={traineeAccident == 'yes'} className="training-btn" strong fill="solid" color="black" onClick={ e => setHeight5(!height5) } aria-expanded={ height5 !== false }><div className="training-text">Highway Driving</div><div className="training-text-back" style={highwayScore !== 0 ? {color: '#95d201'} : {color: 'white'} }>{highwayScore !== 0 ? "Complete" : "Not Completed" }</div><IonIcon style={{ marginLeft: "5px", fontSize: "10vw"}} icon={height5 ? chevronDown : chevronUp}></IonIcon></IonButton>
          <AnimateHeight style={{ width: '100vw', overflowY: 'scroll'}} duration={500} height={ height5 === true ? 'auto' : 0 } id="loadSection">
            <IonCardHeader style={{backgroundColor: "transparent"}}>
              <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>Rate Trainee on their <b>highway / interstate driving</b> based on the following.</span><br/><br/><span style={{fontSize: "12px", lineHeight: '20px'}}>- Lane Control and Lane usage<br/>- Appropriate Speed Control<br/> - Sitting Duck:<br/> Never stop on the side of the road unless you have experienced a true emergency</span></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem style={{width:'100%', padding:'10px'}}>
                  <IonLabel className='ion-text-wrap'>Select a Score</IonLabel>
                  <IonSelect disabled={traineeAccident == 'yes'} value={highwayScore} onIonChange={e => {setHighwayScore(e.detail.value); setHeight5(!height5)}}>
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <div style={{fontSize: "12px", padding: "15px", color: "black"}}>
                    <span>1.	Trainee does not maintain their lane, is in a restricted lane, and does not control their speed or adjust speed for construction zones and changing speed zones. Any time the trainee stops on the side of the road for something other than an emergency it is an automatic 1 in this section.<br/><br/></span>
                    <span>2.	Trainee can drive on the highway, however; is not proficient in more than one of the following areas: maintaining lane control, using the appropriate lane, maintaining an appropriate speed, or adjusting speed for construction zones or changing speed limits.<br/><br/></span>
                    <span>3.	Trainee can drive on the highway; however, is not proficient in only one of the following areas: maintaining lane control, using the appropriate lane, maintaining an appropriate speed, or adjusting speed for construction zones or changing speed limits.<br/><br/></span>
                    <span>4.	Trainee can drive on the highway, however; may have an occasional critical event or one event throughout the day of the following: maintaining lane control, using the appropriate lane, maintaining an appropriate speed, or adjusting speed for construction zones or changing speed limits.<br/><br/></span>
                    <span>5.	Trainee shows proficiency in all areas of highway driving. Trainee maintains lane control and is always in the appropriate lane, always maintains an appropriate speed on the highway, and adjusts their speed appropriately for construction zones and changes in the speed limit.</span>
                  </div>
            </IonCardContent>
          </AnimateHeight>
          
          {/*[...] TABLET*/}

          <IonButton disabled={traineeAccident == 'yes'} className="training-btn" strong fill="solid" color="black" onClick={ e => setHeight6(!height6) } aria-expanded={ height6 !== false }><div className="training-text">Tablet</div><div className="training-text-back" style={tabletScore !== 0 ? {color: '#95d201'} : {color: 'white'} }>{tabletScore !== 0 ? "Complete" : "Not Completed" }</div><IonIcon style={{ marginLeft: "5px", fontSize: "10vw"}} icon={height6 ? chevronDown : chevronUp}></IonIcon></IonButton>
          <AnimateHeight style={{ width: '100vw', overflowY: 'scroll'}} duration={500} height={ height6 === true ? 'auto' : 0 } id="loadSection">
            <IonCardHeader style={{backgroundColor: "transparent"}}>
              <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>Rate Trainee on their <b>tablet usage</b> based on the following.</span><br/><br/><span style={{fontSize: "12px", lineHeight: '20px'}}>- GPS<br/>- Workflow<br/>- Loaded, Empty, Messaging<br/>- DVIR / Pretrip<br/>- HOS App</span></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem style={{width:'100%', padding:'10px'}}>
                  <IonLabel className='ion-text-wrap'>Select a Score</IonLabel>
                  <IonSelect disabled={traineeAccident == 'yes'} value={tabletScore} onIonChange={e => {setTabletScore(e.detail.value); setHeight6(!height6)}}>
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <div style={{fontSize: "12px", padding: "15px", color: "black"}}>
                    <span>1.	Trainee does not understand how to use the tablet and requires direct supervision on all tasks using the tablet.<br/><br/></span>
                    <span>2.	Trainee can use the tablet but does require assistance regularly. Trainee is not proficient in more than one of the following areas: GPS / Navigation, Workflow, messages (including loaded or empty), DVIR or Pretrip Inspection submissions, and the HOS App.<br/><br/></span>
                    <span>3.	Trainee can use the tablet but requires assistance occasionally. Trainee is not proficient in only one of the following areas: GPS / Navigation, Workflow, messages (including loaded or empty), DVIR or Pretrip Inspection submissions, and the HOS App.<br/><br/></span>
                    <span>4.	Trainee can use the tablet but may still require assistance. Trainee shows proficiency in each of the following apps: GPS / Navigation, Workflow, messages (including loaded or empty), DVIR or Pretrip Inspection submissions, and the HOS App.<br/><br/></span>
                    <span>5.	Trainee shows proficiency in using the tablet. The trainee requires no assistance and is proficient in all the following apps: GPS / Navigation, Workflow, messages (including loaded or empty), DVIR or Pretrip Inspection submissions, and the HOS App.</span>
                  </div>
            </IonCardContent>
          </AnimateHeight>

          {/*[...] HOS*/}

          <IonButton disabled={traineeAccident == 'yes'} className="training-btn" strong fill="solid" color="black" onClick={ e => setHeight7(!height7) } aria-expanded={ height7 !== false }><div className="training-text">Service Hours</div><div className="training-text-back" style={hosScore !== 0 ? {color: '#95d201'} : {color: 'white'} }>{hosScore !== 0 ? "Complete" : "Not Completed" }</div><IonIcon style={{ marginLeft: "5px", fontSize: "10vw"}} icon={height7 ? chevronDown : chevronUp}></IonIcon></IonButton>
          <AnimateHeight style={{ width: '100vw', overflowY: 'scroll'}} duration={500} height={ height7 === true ? 'auto' : 0 } id="loadSection">
            <IonCardHeader style={{backgroundColor: "transparent"}}>
              <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>Rate Trainee on their <b>hours of service</b> knowledge based on the following.</span><br/><br/><span style={{fontSize: "12px", lineHeight: '20px'}}>- Understands trip planning and HOS Rules and Regulations</span></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem style={{width:'100%', padding:'10px'}}>
                  <IonLabel className='ion-text-wrap'>Select a Score</IonLabel>
                  <IonSelect disabled={traineeAccident == 'yes'} value={hosScore} onIonChange={e => {setHOSScore(e.detail.value); setHeight7(!height7)}}>
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                  </IonSelect>
                </IonItem>
                <div style={{fontSize: "12px", padding: "15px", color: "black"}}>
                    <span>1.	Trainee does not understand hours of service or trip planning. Is unable to plan out their day or utilize their available time.<br/><br/></span>
                    <span>2.	Trainee needs assistance regularly in understanding available time. Has a general understanding of the hours of service but still does not fully understand the regulation. Trainee cannot plan out their day according to available hours.<br/><br/></span>
                    <span>3.	Trainee understands available hours and hours of service regulations. Trainee does need assistance in trip planning to understand how to utilize available hours and take breaks on time.<br/><br/></span>
                    <span>4.	Trainee understands hours of service and available time. Trainee can plan their trip, but may need assistance routing.<br/><br/></span>
                    <span>5.	Trainee shows proficiency in understanding the hours of service regulations and effectively plans their trip for their available time. Trip plan includes the following areas: routing, scaling their load, potential weigh stations, break locations with back up locations, and on time ETA for delivery. </span>
                  </div>
            </IonCardContent>
          </AnimateHeight>

          {/*[...] DEFENSIVE DRIVING*/}

          <IonButton disabled={traineeAccident == 'yes'} className="training-btn" strong fill="solid" color="black" onClick={ e => setHeight8(!height8) } aria-expanded={ height8 !== false }><div className="training-text">Defensive Driving</div><div className="training-text-back" style={defensiveScore !== 0 ? {color: '#95d201'} : {color: 'white'} }>{defensiveScore !== 0 ? "Complete" : "Not Completed" }</div><IonIcon style={{ marginLeft: "5px", fontSize: "10vw"}} icon={height8 ? chevronDown : chevronUp}></IonIcon></IonButton>
          <AnimateHeight style={{ width: '100vw', overflowY: 'scroll'}} duration={500} height={ height8 === true ? 'auto' : 0 } id="loadSection">
            <IonCardHeader style={{backgroundColor: "transparent"}}>
              <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>Rate Trainee on their <b>defensive driving</b> based on the following.</span><br/><br/><span style={{fontSize: "12px", lineHeight: '20px'}}>- Aim high in steering<br/>- Get the big picture<br/>- Keep your eyes moving<br/>- Leave yourself an out<br/>- Make sure they see you</span></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonItem style={{width:'100%', padding:'10px'}}>
                  <IonLabel className='ion-text-wrap'>Select a Score</IonLabel>
                  <IonSelect disabled={traineeAccident == 'yes'} value={defensiveScore} onIonChange={e => {setDefensiveScore(e.detail.value); setHeight8(!height8)}}>
                    <IonSelectOption value={1}>1</IonSelectOption>
                    <IonSelectOption value={2}>2</IonSelectOption>
                    <IonSelectOption value={3}>3</IonSelectOption>
                    <IonSelectOption value={4}>4</IonSelectOption>
                    <IonSelectOption value={5}>5</IonSelectOption>
                  </IonSelect>
                </IonItem>
                  <div style={{fontSize: "12px", padding: "15px", color: "black"}}>
                    <span>1.  Trainee does not understand defensive driving principles and does not practice the 5 Key Points of Safe Driving.<br/><br/></span>
                    <span>2.	Trainee requires monitoring and does not do more than one of the following: aim high in steering, get the big picture, keep your eyes moving, leave yourself an out, and make sure they see you. <br/><br/></span>
                    <span>3.	Trainee understands the 5 Key Points of Safe Driving; however, needs improvement in one of the following areas: aim high in steering, get the big picture, keep your eyes moving, leave yourself an out, and make sure they see you.<br/><br/></span>
                    <span>4.	Trainee understands and practices the 5 Key Points of Safe Driving. Trainee may still have one critical event or rare event in the day regarding Defensive Driving practices.<br/><br/></span>
                    <span>5.	Trainee shows proficiency in Defensive Driving. Understands the 5 Key Points of Safe Driving, and is consistently practicing these key points.</span>
                  </div>
            </IonCardContent>
          </AnimateHeight>

          <IonItemDivider className='training-header'>
            <h2 style={{width: "100%", textAlign: "center"}}>Total Score: <span style={{textAlign: "end"}}>{backingScore + turningScore + tabletScore + roadHabitsScore + hosScore + highwayScore + cityScore + defensiveScore}/40</span></h2>
          </IonItemDivider>

          <IonItem>
            <div style={{textAlign: "start", fontSize: "16px", fontWeight: "600", margin: "auto 25px auto 75px"}}>Correctly Secured Load?</div>
            <IonSelect style={{width: "70px"}} disabled={traineeAccident == 'yes'} value={securedLoad} onIonChange={e => {setSecuredLoad(e.detail.value);}}>
              <IonSelectOption value={"yes"}>Yes</IonSelectOption>
              <IonSelectOption value={"no"}>No</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem>
            <div style={{textAlign: "start", fontSize: "16px", fontWeight: "600", margin: "auto 25px auto 75px"}}>Ready for solo?</div>
            <IonSelect style={{width: "70px"}} disabled={traineeAccident == 'yes'} value={traineeReady} onIonChange={e => {setTraineeReady(e.detail.value);}}>
              <IonSelectOption value={"yes"}>Yes</IonSelectOption>
              <IonSelectOption value={"no"}>No</IonSelectOption>
            </IonSelect>
          </IonItem>

            <IonButton id="western-button" className="mb-10" expand="block" disabled={reportTimer === 'Not Ready'} onClick={e => setShowModal1(true)}>Submit Report</IonButton>

       </IonList>
       :
       <>
       <IonCard>
       <IonCardHeader>
           <IonCardTitle>No Course Assigned</IonCardTitle>
         </IonCardHeader>
           <IonCardContent style={{textAlign: 'center'}}><span style={{color: 'white'}}>Assign a course for this trainee below</span></IonCardContent>
       </IonCard>
       <IonButton id="western-button" expand="block" onClick={e => {setShowCourseModal(true); setChangingCourse(true); getCourseInfo();}}>Assign Course</IonButton>
       </>
      } </>
      //<--- Road test call --->
      : <RoadTest traineeCode={traineeCode} trainerCode={trainee.trainerCode} reportId={setReportId} submittedTest={testSubmitted} hideTest={resetForm} />
      
      } </> : <> { !noTrainees ? 
        <IonList color="light" style={{padding: 0}}>
          <IonItem lines="none" color='light'>
            <div className='item-container'>
              <p className="title">Select From Current Trainees</p>
              <p className='subtitle'>Found {trainees.length} trainees.</p>
            </div>
          </IonItem>
          <IonItem lines="none" color='light'>
            <Link to="/newtrainee" style={{width: "100vw"}} className="western-button-link">
                <div className="green">
                    <img src={newIcon} alt="orientation" className="link-icon"/><p>New Trainee</p>
                </div>
            </Link>
          </IonItem>
        {trainees.map(function (trainee) {
          console.log('trainee',trainee)
          // let reportReady
          // checkReportReady(trainee.traineeCode, trainee.currentCourseId).then(data => {
          //   console
          //   reportReady = data
          // })
              return (
                 <div style={{ width: "100%"}}>
                <IonItem key={uniqueKey(trainee.traineeCode)} onClick={() => {setTraineeData(trainee); setShowNewReport(true)} } lines="none" color="light">
                  <div className='item-container' style={{ backgroundColor: "#ffffff", padding: "4px", borderRadius: "10px"}}>
                    <div className="space-between">
                      <div style={{ width: "80%"}}>
                        <p style={{ fontSize:"medium", margin: "4px"}}>{trainee.traineeCode}</p>
                        <div style={{ display: "flex"}}><p style={{ fontSize: "small", color: "#a7a7a7", padding: "4px 8px", backgroundColor: "#E5F4E0", borderRadius: "16px", textAlign:"center", margin: 0 }}>Course ID: {trainee.currentCourseId === 0 ? 'No Course' : trainee.currentCourseId}</p></div>
                        <p style={{ fontSize: "small", margin: "4px"}}>Day {trainee.currentCourseDay}</p>
                      </div>
                      <IonIcon icon={chevronForward} style={{ fontSize: "large"}}></IonIcon>
                    </div>
                  </div>

                </IonItem>
                <ReportHistory trainee={trainee}/>
               </div>
              )
            })}
          </IonList>
        
      : <IonItem lines="none" color="light">
        <div className='item-container'>
          <p className="title">You have no trainees.</p>
          <p className='subtitle'>Claim now</p>
          <Link to="/newtrainee" className="western-button-link">
                    <div className="green">
                        <img src={newIcon} alt="orientation" className="link-icon"/><p>New Trainee</p>
                    </div>
                </Link>
        </div>
      </IonItem>
      } </>
    }  
    </IonContent>

      {/*Report Modal */}
      <IonModal isOpen={reportModal}> 
      
          <div style={{overflowY: 'scroll'}}>
          
          <DailyReports>
          
          <IonCard>
          
            <IonList>
              <IonItem>
                <IonLabel>Trainee</IonLabel>
                <IonNote slot="end" color="black">{traineeCode}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Trainer Code</IonLabel>
                <IonNote slot="end" color="black">{report.trainerCode}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Dm Code</IonLabel>
                <IonNote slot="end" color="black">{report.dmCode}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Road Test</IonLabel>
                <IonNote slot="end" color="black">{report.roadTest !== null ? 'Yes' : 'No'}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Backing</IonLabel>
                <IonNote slot="end" color="black">{report.backingScore == 1 ? 'Yes' : 'No'}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Turning</IonLabel>
                <IonNote slot="end" color="black">{report.turningScore == 1 ? 'Yes' : 'No'}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Driving Habits</IonLabel>
                <IonNote slot="end" color="black">{report.roadHabitsScore}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>City Driving</IonLabel>
                <IonNote slot="end" color="black">{report.cityScore}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Interstate / Highway Driving</IonLabel>
                <IonNote slot="end" color="black">{report.highwayScore}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Tablet Usage</IonLabel>
                <IonNote slot="end" color="black">{report.tabletScore}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>HOS Knowledge</IonLabel>
                <IonNote slot="end" color="black">{report.hosScore}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Defensive Driving</IonLabel>
                <IonNote slot="end" color="black">{report.defensiveScore}</IonNote>
              </IonItem>
              <IonItem>
                <IonLabel>Overall Grade</IonLabel>
                <IonNote slot="end" color="black">{report.overallScore}</IonNote>
              </IonItem>
            </IonList>
          </IonCard>
          </DailyReports>    
          </div>
          
          
          <IonList className='mb-20'>
              {reports.map(function (dayreport: any) {
                return <div style={{ width: "100%" }}>
                  <IonItem onClick={function () { getReportDetails(dayreport.date); } }>
                    <IonLabel>Course Day</IonLabel>
                    <IonNote slot="end" color="black">{dayreport.date}</IonNote>
                  </IonItem>
                </div>;
              })}
          </IonList>   
            
      
      </IonModal>
     
      {/* Profile Modal: */}
      <IonModal isOpen={profileModal} cssClass='my-custom-class'>
      
                  <div style={{ overflowY: 'scroll' }}>
                  <IonButton id="western-button" onClick={e => {setHasTrainee(false); setProfileModal(false)}}>⌫ Trainees</IonButton>
                  <IonCard style={{border: "2px solid white", color:"white"}} >
                    <IonCardHeader>
                      <IonCardTitle onClick={e => collapseContent("content", false)}>"To-Do" List: <span style={{float:'right'}}>[ ⇅ ]</span></IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent class="dropdown-list" id="content">
                      <div>
                        <div style={{ backgroundColor: 'darkgray', padding: '15px', borderBottom: '3px solid #ddd' }}>
                          <b style={{ fontSize: '14px' }}>* Select an item below and click "GO!"</b>
                          <h5 style={{ color: "black", fontSize: "18px", fontWeight: 'bold' }}>-- Tasks:</h5>
                          
                          <IonButton id="western-todo_0" expand="block"  key={uniqueKey(trainee.traineeCode)} onClick={e => {highlightContent("western-todo_0", "GO-button", "western-todo_1"); setChangingCourse(true); collapseContent("content", true)}}>Daily Report: [...]</IonButton>
                          <IonButton id="western-todo_1" expand="block" disabled={trainee.currentCourseId === 0} onClick={ e => {highlightContent("western-todo_1", "GO-button", "western-todo_0"); setShowRoadTest(true); collapseContent("content", true)}}>Road Test: [...]</IonButton>
                          <h5 style={{ color: "black", fontSize: "18px", fontWeight: 'bold' }}>-- Manage:</h5>
                          <IonButton id="western-button" expand="block" onClick={e => {setShowCourseModal(true); setChangingCourse(true); getCourseInfo(); }}>Assign Course:</IonButton>
                          {/* <IonButton id="western-button" expand="block"   onClick={e => {setShowCourseModal(false); setChangingCourse(false)}}>Assign Course: </IonButton> */}
                          
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                    
                    <IonList>
                      <IonListHeader>
                        <span style={{color: "black", fontSize: "24px"}}>Trainee: <b>{trainee.traineeCode}</b></span>
                      </IonListHeader>
                      <IonItem>
                        <IonLabel>Student</IonLabel>
                        <IonNote slot="end" color="black">{trainee.traineeName}</IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>DM Code</IonLabel>
                        <IonNote slot="end" color="black">{trainee.dmCode}</IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Assigned Course Id</IonLabel>
                        <IonNote slot="end" color="black">{trainee.currentCourseId}</IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Current Course Day</IonLabel>
                        <IonNote slot="end" color="black">{trainee.currentCourseDay}</IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Released</IonLabel>
                        <IonNote slot="end" color="black">{trainee.released ? trainee.released.toString().replace('T', ' ').slice(0, -14) : "Not released"}</IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Passed</IonLabel>
                        <IonNote slot="end" color="black">{trainee.passed ? "Yes" : "No"}</IonNote>
                      </IonItem>
                    </IonList>
                    </div>
            
                  <div>
                    <IonButton id="GO-button" className-="mb-20" expand="block" onClick={function () { setProfileModal(false); } }>GO!</IonButton>
                  </div>
          </IonModal>
    </IonPage>
  );
};

export default TrainingDay;
function getReportDetails(date: any) {
  throw new Error('Function not implemented.');
}



