import React, { useState }  from "react";

import homeIcon from "../imgs/home-nav.png"
import searchIcon from "../imgs/search-nav.png"
import newsIcon from "../imgs/news.png"
import helpIcon from "../imgs/help.png"
import { body } from "ionicons/icons";
import {Link} from "react-router-dom";

type Props = {
    onSearch: (target: boolean) => void
}
function isSearching () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const product = urlParams.get('search')
    if(product) {
      return true
    } else {
      return false
    }
}
const Nav = ({ onSearch}: Props) => {
    const [search, setSearch] = useState(false)
    function handleMode () {
        setSearch(!search)
        onSearch(!search)
    }
    return (
        <div className="item-container" id="navi">
            <div className="nav-items">
                <Link to="/Home" style={{ textDecoration: "none"}}>
                    <div>
                        <img src={homeIcon} alt="home"/>
                        <p style={{ color: window.location.href.match('/Home') && (!search && !isSearching()) ? "#99cc00" : "#ffffff"}}>Home</p>
                    </div>
                </Link>
                <div onClick={() => {handleMode()}}>
                    <img src={searchIcon} alt="search"/>
                    <p style={{ color: search || isSearching() ? "#99cc00" : "#ffffff"}}>Search</p>
                </div>
                <Link to="/News" style={{ textDecoration: "none"}}>
                    <div>
                        <img src={newsIcon} alt="news"/>
                        <p style={{ color: window.location.href.match('/News') && (!search && !isSearching()) ? "#99cc00" : "#ffffff"}}>News</p>
                    </div>
                </Link>
                <Link to="/Support" style={{ textDecoration: "none"}}>
                    <div>
                        <img src={helpIcon} alt="help"/>
                        <p style={{ color: window.location.href.match('/Support') && (!search && !isSearching()) ? "#99cc00" : "#ffffff"}}>Help</p>
                    </div>
                </Link>
            </div>
           
        </div>
    )
}

export default Nav
