import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {alertCircleOutline} from 'ionicons/icons';
import {Link} from "react-router-dom";
import {ApiService} from "../api/ApiService";
import {PaycheckHistory} from "../api/model/PaycheckHistory";

const Payroll: React.FC = () => {

  const [lastNum, setLastNum] = useState(0);
  const [checks, setChecks] = useState<PaycheckHistory[]>([]);
  const [showNoPayroll, setShowNoPayroll] = useState(false)

  useEffect(() => {
    loadMore(0);
  }, []);

  function loadMore(lastNum: number) {
    ApiService.getPaycheckHistory("payrollPayCheck", {start: lastNum, length: 5}).then(it => {
      setChecks(checks.concat(it));
      setShowNoPayroll(false);
      setLastNum(checks.length);
    });
  }

  const displayDate = (date: string) => {
    if(date) {
      let parts = date.split('-')
      return parts[1]+"/"+parts[2]+"/"+parts[0]
    } else {
      return "-"
    }
  }

  return (
    <IonPage>
      <IonHeader >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Payroll History</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {checks.map(function(check){
              return (
                <Link to={"/paychecks/" + check.date} key={check.date} className="load-history">
                <IonItem color="light" lines="none">
                  <div className='item-container'>
                    {/* <h4 className='title boldest' style={{ color: "#99cc00"}}>{load.orderNumber}</h4> */}
                    <p>Date: {displayDate(check.date)} </p>
                    <p>Net Amount: {check.net}</p>
                    <p className='subtitle'>See Detail</p>
                  </div>
                </IonItem>
              </Link>
              )
            
          })}
          {showNoPayroll ? <IonItem lines="none">
            <div className='item-container'>
              <p style={{ display: "flex", alignItems: "center", gap: "4px", color: "#FF0000", padding: "8px"}}>No Payroll History <IonIcon icon={alertCircleOutline} style={{ fontSize: "medium"}}></IonIcon></p>
              <div className="western-button-link">
                <div style={{ display: "flex", justifyContent: "space-evenly"}}>
                    <p>Call Driver Payroll</p>
                    <a href="tel:615-777-1682">(615) 777-1682</a>
                </div>
            </div>
            </div></IonItem> :<IonItem lines="none"><div className='item-container'><button  className="western-button submit"onClick={e => {loadMore(5 + lastNum)}} color="#ffffff">Load More</button></div></IonItem>}
      </IonContent>
    </IonPage>
  );
};

export default Payroll;
