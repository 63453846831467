import React, {useEffect, useState} from "react"
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from "@ionic/react"
import {ApiService} from "../api/ApiService";
import {Leaderboard} from "../api/model/Leaderborad";

function Rewards () {
    const [leaders, setLeaders] = useState<Leaderboard[]>([])
    const [isUser, setIsUser] = useState(false)
    const [searchedDriver, setSearchedDriver] = useState<Leaderboard>()
    const [driverRank, setDriverRank] = useState(0)
    const [searchedUsername, setSearchedUsername] = useState("")
    const [types, setTypes] = useState(['Miles','MPG','Pretrip'])
    const [selectedType, setSelectedType] = useState('Miles')
    const [gamify, setGamify] = useState(0)

    useEffect(() => {
        if (leaders.length === 0) {
            getLeaderboard(selectedType, gamify)
        }

        if(leaders.length > 0) {
            leaders.forEach((driver) => {
                //console.log(driver)
                if (driver.driverCode) {
                    if(localStorage.getItem("username") === driver.driverCode.trim()) {
                        setSearchedDriver(driver)
                        setDriverRank(driver.rank)
                        setSearchedUsername(driver.driverCode)
                        setIsUser(true)
                    }
                }
            })

        }
        ApiService.sendEventCommand("Rewards Check", "eventCheck", "User has checked the gamify screen");
    }, [leaders])

    // function weekOf () {
    //     const today = new Date()
    //     const dayOfWeek = today.getDay()
    //     const firstDayOfWeek = today.getDate() - dayOfWeek + 1
    //     return (today.getMonth() + 1) + "/" + firstDayOfWeek + "/" + today.getFullYear()
    // }

    function getLeaderboard(type: string, span: number) {
        ApiService.getLeaderboard("apiService", {span: span === 0 ? 'weekly' : 'monthly', type: (type || '').toLowerCase()}).then(it => setLeaders(it));
    }

    const formatDriverName = (fullname: string) => {
        if(typeof fullname !== 'undefined' && fullname !== null) {
             const firstLast = fullname.trim().split(' ')
             return firstLast[0] + " " + firstLast[firstLast.length-1].slice(0,1) + "."
        }
    }

    const leaderboardRows = leaders.map((driver, index) => {
        console.log(driver)
        return (
            <div style={{display: "grid", gridTemplateColumns: "1fr 2fr 2fr 1fr", color: "#212121", textAlign: "left", padding: "8px", fontSize: "small"}} id={driver.driverCode} key={index}><p>{driver.rank}.</p><p>{formatDriverName(driver.driverName)}</p><p>{driver.driverCode}</p><p>{driver.value ? types[1] === "MPG" ? driver.value.toFixed(2) : Math.floor(driver.value) : '0'}</p></div>
        )

    })
    const skeletonRows = Array.from(Array(50).keys()).map(() => {

         return (
            <div id="skeleton-row"><div className="loading-main-text"></div><div className="loading-main-text"></div><div className="loading-main-text"></div><div className="loading-main-text"></div></div>
         )

     })
     function handleBoards (type: string, index: number) {
        setIsUser(false);
        // let temp = types
        // temp[index] = temp[1]
        // temp[1] = type
        // setTypes([...types])
        setSelectedType(type)
        getLeaderboard(type, index)
     }
     const selection = types.map((type,index) => {
        return <p style={{ fontWeight: "900", fontSize: "medium", color: selectedType === type ? "#212121" : "#a7a7a7"}} onClick={() =>handleBoards(type, index)}>{type}</p>
     })

     const formatValue = (value: number) => {
        return types[1] === "MPG" && value ? value.toFixed(2) : value
     }
    return (
        <IonPage>
        <IonHeader>
        <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/Home" text=""></IonBackButton>
                    </IonButtons>
                    <IonTitle>Standings</IonTitle>
                </IonToolbar>
        </IonHeader>
        <IonContent color="light" fullscreen>
            <IonItem lines="none" color="light">
                <div className="item-container">
                    <h1 style={{ fontWeight: "900", fontSize: "min(8vw,48px)"}}>Leaderboard</h1>
                </div>
            </IonItem>
            <IonItem lines="none" color="light">
                <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)"}}>
                    {selection}
                    <div>
                        <select className="input"  onChange={(e) => {handleBoards(selectedType, parseInt(e.target.value))}} style={{ height: "20px", width: "auto"}}>
                            <option value={0}>Week</option>
                            <option value={1}>Month</option>
                        </select>
                    </div>
                </div>
            </IonItem>
            <div style={{ padding: '0 8px'}}>
                {leaders.length > 1 ? <div className="leaderboard-card">

                    <div>
                        <div className="item-container flex-center-even"><p>Rank</p><p>Driver</p><p>Username</p><p>{selectedType}</p></div>
                        {isUser ? <div className="lb-ft-row" id={searchedDriver?.driverCode} key={driverRank}><p>{driverRank}.</p><p>{formatDriverName(searchedDriver?.driverName ?? "")}</p><p>{searchedDriver?.driverCode}</p><p>{typeof searchedDriver !== 'undefined' ? searchedDriver?.value ? types[1] === "MPG" ? searchedDriver?.value.toFixed(2) : Math.floor(searchedDriver?.value) : '0' : '0'}</p></div> : null}
                        <div>
                        {leaderboardRows}

                        </div>

                    </div>

                </div> : <IonItem lines="none" color="light"><div className="item-container" ><p className="subtitle">It doesn't appear you have any {selectedType} score for the {gamify ? 'month' : 'week'}? </p><p  style={{ color: "#ff0000"}} className="subtitle">Please make sure you have completed loads this {gamify ? 'month' : 'week'}, and you were or currently are seated.</p></div></IonItem>}
            </div>

        </IonContent>
        </IonPage>
    )
}

export default Rewards
