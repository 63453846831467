import {IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from "@ionic/react"
import React, {useEffect, useState} from "react"
import {addCircleOutline} from 'ionicons/icons';
import {Camera} from '@capacitor/camera';
import {base64FromPath} from '@ionic/react-hooks/filesystem';


import placeholder from "../imgs/placeholder_profile.png"
import {ApiService} from "../api/ApiService";
import {PhotoApiService} from "../api/PhotoApiService";
import {PhotoUploadRequest} from "../api/model/PhotoUploadRequest";
import {toastStore} from "../components/AppToast";
import {userStore} from "../api/AuthService";
import {useHistory} from "react-router-dom";

const timages: any[] = [];

function EditProfile () {

    const history = useHistory();

    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [submittingPhoto, setSubmittingPhoto] = useState(false)
    const [nickname, setNickname] = useState("")
    const [unit, setUnit] = useState("")
    const [images, setImages] =  useState<PhotoUploadRequest[]>([]);
    const [imageWebpath, setImageWebpath] = useState("")

    useEffect(() => {
        setImages([]);
        setUnit(userStore.getState().driver.unit);
        setPhone(userStore.getState().driver.phone);
        setEmail(userStore.getState().driver.email);
        setNickname(userStore.getState().driver.nickname);
        console.log("email", userStore.getState().driver?.email);
    }, []);

    enum CameraResultType {
        Uri = 'uri',
        Base64 = 'base64',
        DataUrl = 'dataUrl'
    }

    async function takePicture() {
      const image = await Camera.getPhoto({
        quality: 30,
        allowEditing: false,
        resultType: CameraResultType.Uri
      });
      const fileName = new Date().getTime() + '.jpeg';
      const base64Data = await base64FromPath(image.webPath!);
      const newPhotos = [{
        filepath: fileName,
        webviewPath: image.webPath, 
        data: base64Data
      }, ...images];
      setImages(newPhotos)
      setImageWebpath(image.webPath!)
      timages.push(image)
     
    }

    async function submitPhotos() {
        if (!images?.length) {
            return;
        }
        setSubmittingPhoto(true);
        ApiService.photoSubmission("EditProfile", {refId: userStore.getState().user.id, load: '', division: '', trailer: '', unit: '', type: 'profile', dmCode: ''}).then(() => {
            PhotoApiService.uploadPhotos(images, userStore.getState().user.id, 'profile').then(() => {
                toastStore.getState().setSuccessToast('Success. Your photo was uploaded.');
            });
        }).finally(() => {
            setSubmittingPhoto(false);
        });
    }

    function handlePhone (phone: string) {
      if (phone.length > 0) {
        setPhone(phone.replace('-', ''))
      } else {
        setPhone('')
      }
    }

    function editProfile() {
        ApiService.driverProfileUpdate("EditProfile", {phone, email}).then(() => {
            userStore.getState().reloadDriverProfile().then(() => {
                toastStore.getState().setSuccessToast('You updated your profile');
                history.push('/');
            });
        });
    }

    return (
        <IonPage>
         <IonModal isOpen={submittingPhoto} id="transparent">
                <div className="modal-div">
                    <div id="modal">
                        <div className='loading-div'>
                          <p>Your photo is uploading... Please wait.</p>
                          <div className="snippet" data-title=".dot-flashing" style={{ margin: "4px 16px"}}>
                            <div className="stage">
                              <div className="dot-flashing"></div>
                            </div> 
                          </div>
                        </div>
                    </div>
                </div>
            </IonModal>
        <IonHeader >
               <IonToolbar color="primary">
               <IonButtons slot="start">
                   <IonBackButton defaultHref="/Home"  text=""/>
                 </IonButtons>
                 <IonTitle>Edit Profile</IonTitle>
               </IonToolbar>
        </IonHeader>
        <IonContent color="light" fullscreen>
          <IonItem lines="none" color="light">
            <div className="item-container">
            <div>
                <p className="subtitle">Update your contact info, or change your profile picture.</p>
                <div>
                    <div style={{ textAlign: "center"}}>
                    {images.length > 0 ?
                <img className="profile-photo" alt="new profile img" src={imageWebpath} />
                        : <img className="profile-photo" onError={({currentTarget}) => {
                            currentTarget.onerror = null;
                            currentTarget.src = placeholder
                        }} src={userStore.getState().profilePictureUrl} alt="avatar"/>}
                        {/* <img id="profile-avatar" src="https://st2.depositphotos.com/1968353/8891/i/600/depositphotos_88913942-stock-photo-semi-trailer-truck.jpg)" alt="avatar"/> */}
                        <IonIcon style={{color: "#fff", fontSize: "24px", backgroundColor: "#99cc00", borderRadius: "50%", position: "relative", left: "-24px", top: "-18px"}} icon={addCircleOutline}
                                 onClick={() => takePicture()}></IonIcon>
                    </div>
                    <div className="edit-info">
                      <div className="input-container">
                        <input id="phone" className="input" type="tel" placeholder=" " value={phone} onChange={e => handlePhone(e.target.value!)}/>
                        <p className="placeholder" >Phone</p>
                      </div>
                      <div className="input-container">
                        <input id="email" className="input" type="email" placeholder=" " value={email} onChange={e => setEmail(e.target.value!)}/>
                        <p className="placeholder" >Email</p>
                      </div>
                    </div>
                </div> 
            </div>
          
            </div>
            </IonItem>
            <IonItem lines='none' color="light">
              <div className="item-container">
                <button className="western-button submit" onClick={() => {submitPhotos(); editProfile()}}>SAVE</button>
              </div>
            </IonItem>

        </IonContent>
        </IonPage>
          
    )
}

export default EditProfile
