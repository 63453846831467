import { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonModal, IonButtons, IonItemGroup, IonItem, IonSearchbar, IonToast, IonIcon, IonList, IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { close, chatboxEllipsesOutline } from 'ionicons/icons';
import logoutIcon from "../imgs/logout.png"

//Google Analytics:
import React from 'react';

// Example images //
import { ApiService } from '../api/ApiService';
import { toastStore } from '../components/AppToast';

interface selectedVehicle {
  id: string;
  message: string;
  terminal: string;
  addedVehicle: boolean;
  issue: string;
  kotl: string;
  type: string;
  loaded: string;
}

const YardDog: React.FC<{moduleHistory: string}> = ({ moduleHistory }) => {

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [units, setUnits] = useState<any[]>([]);
  const [vehicleType, setVehicleType] = useState("unit");
  const [selectedVehicleType, setSelectedVehicleType] = useState("unit")
  const [filteredUnits, setFilteredUnits] = useState<any[]>([])
  const [trailers, setTrailers] = useState<any[]>([]);
  const [filteredTrailers, setFilteredTrailers] = useState<any[]>([]);
  const [selectedVehicles, setSelectedVehicles] = useState<any[]>([]);
  const [filteredSelectedVehicles, setFilteredSelectedVehicles] = useState<any[]>([]);
  const [vehicle, setVehicle] = useState<selectedVehicle>();
  const [selectVehicleId, setSelectedVehicleId] = useState("");
  const [checkedVehicle, setCheckedVehicle] = useState(false);
  const [comment, setComment] = useState("");
  const [issue, setIssue] = useState("");
  const [activeKOTL, setActiveKOTL] = useState("false");
  const [loaded, setLoaded] = useState("false");
  const [newIDVehicle, setNewIDVehicle] = useState("")
  const [currentTime, setCurrentTime] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [successPhoto, setSuccess] = useState(false);
  const [toast, setToast] = useState<string>("");

  useEffect(() => {
    getDate()
  }, []);

  const getDate = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const mins = date.getMinutes()
    setCurrentTime(month + "/" + day + "/" + year + " " + hours + ":" + mins)
  }

  function getVehiclesByTerminal(terminal: string) {
    setFilteredTrailers([]);
    setFilteredUnits([]);
    setFilteredSelectedVehicles([]);
    if (terminal !== 'none' && terminal !== '') {
      ApiService.ydVehiclesByTerminal("yardDog", {terminal: terminal}).then(it => {
        // concatUnits(it);
        parseLastAvailableVehicles(it);
      });
    }
  }

  function getLastReport(terminal: string) {
    if (localStorage.getItem("yardReport") === null) {
      ApiService.ydLatest("yardDog", {terminal: terminal}).then(it => {
        parseReport(it)
      })
    } else {
      let tempArray: any = JSON.parse(localStorage.getItem("yardReport")!)
      setSelectedVehicles(tempArray);
      setFilteredSelectedVehicles(tempArray);
    }
  }

  function parseLastAvailableVehicles(vehicles: any) {
    let tempUnitArray: any[] = [];
    let tempTrailerArray: any[] = [];
    if (vehicles.units.length !== 0) {
      vehicles.units.forEach((element: any) => {
        let vehicleObj = {
          message: element.comments,
          id: element.unitNumber,
          issue: element.issue,
          kotl: element.kotl.data[0] == 0 || element.kotl.data[0] == null ? "false" : "true",
          loaded: element.loaded.data[0] == 0 || element.loaded.data[0] == null ? "false" : "true",
          type: element.vehicleType,
          addedTrailer: element.addedVehicle,
        }
        tempUnitArray.push(vehicleObj);
      });
    }
    if (vehicles.trailers.length !== 0) {
      vehicles.trailers.forEach((element: any) => {
        let vehicleObj = {
          message: element.comments,
          id: element.unitNumber,
          issue: element.issue,
          kotl: element.kotl.data[0] == 0 || element.kotl.data[0] == null ? "false" : "true",
          loaded: element.loaded.data[0] == 0 || element.loaded.data[0] == null ? "false" : "true",
          type: element.vehicleType,
          addedTrailer: element.addedVehicle,
        }
        tempTrailerArray.push(vehicleObj);
      });
    }
    setTrailers(tempTrailerArray);
    setFilteredTrailers(tempTrailerArray);
    setUnits(tempUnitArray);
    setFilteredUnits(tempUnitArray);
  }

  function parseReport(reportedVehicles: any) {
    setSelectedVehicles([]);
    setFilteredSelectedVehicles([]);
    let tempArray: any[] = []
    reportedVehicles.result.forEach((element: any) => {
      let vehicleObj = {
        message: element.comments,
        id: element.unitNumber,
        issue: element.issue,
        kotl: element.kotl.data[0] == 0 || element.kotl.data[0] == null ? "false" : "true",
        loaded: element.loaded.data[0] == 0 || element.loaded.data[0] == null ? "false" : "true",
        type: element.vehicleType,
        addedTrailer: element.addedVehicle,
      }
      tempArray.push(vehicleObj);
    });
    setSelectedVehicles(tempArray);
    setFilteredSelectedVehicles(tempArray);
  }

  function addVehicle(selectedUnit: string, vehicleType: string, vterm: string, addedTrailer: boolean, message: string, issue: any, kotl: any, loaded: string) {
    let tempArray: any[] = selectedVehicles;
    if (selectedVehicles.filter(function(e) { return e.id === selectedUnit; }).length === 0) {
      let newVehicleObj: selectedVehicle = {
        id: selectedUnit,
        message: message.replace(/,/g, ""),
        terminal: vterm,
        addedVehicle: addedTrailer,
        issue: issue,
        kotl: kotl,
        type: vehicleType,
        loaded: loaded
      }
      tempArray.push(newVehicleObj)
      if (vehicleType === "unit") {
        let tempUnit: any[] = filteredUnits.filter((ex) => ex !==  selectedUnit)
        setFilteredUnits(tempUnit);
      } else {
        let tempTrailer: any[] = filteredTrailers.filter((ex) => ex !==  selectedUnit)
        setFilteredTrailers(tempTrailer);
      }
      setSelectedVehicles(tempArray);
      setFilteredSelectedVehicles(tempArray);
      localStorage.setItem("yardReport", JSON.stringify(tempArray))
      setNewIDVehicle("");
      setComment("");
      setIssue("");
      setActiveKOTL("false");
      setLoaded("false");
    }
    else {
      console.log("already here");
    }
  }

  function removeVehicle(selectedUnit: any) {
    let holdSelectArray: any[] = selectedVehicles;
    let tempSelectArray: any[] = holdSelectArray.filter((ext) => ext.id !== selectedUnit.id );
    setSelectedVehicles(tempSelectArray);
    setFilteredSelectedVehicles(tempSelectArray);
    localStorage.setItem("yardReport", JSON.stringify(tempSelectArray))
    setComment("");
  }

  function filterUnits(searchText: string): void {
      if (searchText?.length < 2) {
          setFilteredUnits(units);
          return;
      }
      const normalizedText = searchText.toLowerCase();
      setFilteredUnits(units?.filter(it => it.id?.toLowerCase().includes(normalizedText) || it.id?.toLowerCase().includes(normalizedText) || it.id?.includes(normalizedText)));
  }

  function filterTrailers(searchText: string): void {
    if (searchText?.length < 2) {
        setFilteredTrailers(trailers);
        return;
    }
    const normalizedText = searchText.toLowerCase();
    setFilteredTrailers(trailers?.filter(it => it.id?.toLowerCase().includes(normalizedText) || it.id?.toLowerCase().includes(normalizedText) || it.id?.includes(normalizedText)));
  }

  function filterVehicles(searchText: string): void {
    filterTrailers(searchText)
    filterUnits(searchText);
  }

  function filterSelectedVehicles(searchText: string) {
    if (searchText?.length < 2) {
      setFilteredTrailers(selectedVehicles);
      return;
    }
    const normalizedText = searchText.toLowerCase();
    setFilteredSelectedVehicles(selectedVehicles?.filter(it => it.id?.toLowerCase().includes(normalizedText) || it.id?.toLowerCase().includes(normalizedText) || it.id?.includes(normalizedText)));
  }

  function addMessageToVehicle(id: string, message: string, activeVehicle: any, issue: string, kotl: string, loaded: string) {
    let tempObj = activeVehicle;
    tempObj.id = id;
    tempObj.message = message;
    tempObj.issue = issue;
    tempObj.kotl = kotl;
    tempObj.loaded = loaded;
    tempObj.type = vehicleType;
    setVehicle({...vehicle!, message: message, issue: issue, kotl: kotl })
    let tempArray: any = [];
    selectedVehicles.forEach((element: any) => {
      if (element.id === tempObj.id) {
        tempArray.push({...vehicle!, message: message, issue: issue, kotl: kotl })
      } else {
        tempArray.push(element);
      }
    });
    setSelectedVehicles(tempArray);
    setFilteredSelectedVehicles(tempArray)
    localStorage.setItem("yardReport", JSON.stringify(tempArray))
    setComment("")
  }

  function checkKTOL() {
    for (let index = 0; index < selectedVehicles.length; index++) {
      if (selectedVehicles[index].kotl === "true" && selectedTerminal !== 'bloomington') {
        submitKOTL();
        break;
      }
    }
  }

  async function createCSV() {
    const yardCsv: any = [
      ['Unit Number', 'Unit Type', 'Comments', "Issue", "Loaded", "KOTL", 'Terminal', 'Timestamp'],
        ...selectedVehicles.map((obj: any) => [
          obj.id,
          obj.type,
          obj.message,
          obj.issue,
          obj.loaded,
          obj.kotl,
          selectedTerminal,
          currentTime
      ])
    ]
    .map((e) => e.join(','))
    .join('\n')
    try {
      const file = new File([yardCsv], 'Yard Check ' + selectedTerminal + " " + currentTime, {type: 'text/csv'})
      await submitForm(file);
    } catch (error) {
      console.warn(error);
    }
  }

  async function submitForm(data: any) {
    let emailList = "scarney@westernexp.com, mrainbolt@westernexp.com, kbatesole@westernexp.com"
    if (selectedTerminal === 'bloomington') emailList = "scarney@westernexp.com, mrainbolt@westernexp.com, kbatesole@westernexp.com, tnemechek@westernexp.com"
    ApiService.sendFileEmail("Yard Check", {
        email: emailList,
        subject: `Yard Check Report ${selectedTerminal} - ${currentTime}`,
    },
    {
      file1: {
        fileName: `yardCheck-${selectedTerminal}-${currentTime}.csv`,
        file: data,
      }
    })
    .then(() => {ApiService.sendEventCommand("Yard Check", "eventCheck", "User submitted a yard check form")})
    .then(() => {toastStore.getState().setSuccessToast('Success. Your report was submitted.'); localStorage.removeItem("yardReport")});
  }
    
  function submitReport() {
    selectedVehicles.forEach((element: selectedVehicle) => {
      ApiService.ydInsert("yardDog", {
        terminal: element.terminal, unitNumber: element.id, unitType: element.type, comments: element.message, onYard: 1, kotl: element.kotl === "false" ? 0 : 1, loaded: element.loaded === "false" ? 0 : 1, issue: element.issue
      }).then(it => {
        console.log("it", it);
      });
    });
  }

  function submitKOTL() {
    let tempArray: any[] = [];
    selectedVehicles.forEach((element: selectedVehicle) => {
      if (element.kotl === "true") {
        tempArray.push(element)
      }
    });
    const emailBody = `Yard check with KOTL lane issues has been submitted with the following trailers: ${tempArray.map(element => {
      return "" + element.id
    })}`;
    ApiService.sendEmail("yardCheck", {to: ["scarney@westernexp.com", "mrainbolt@westernexp.com", "kbatesole@westernexp.com"], subject: `Yard Check KOTL Request ${selectedTerminal} - ${currentTime}`, body: emailBody}).then(() => {});
  }

  return (
    <IonPage>
    <IonHeader >
        <IonToolbar color="primary">
          <IonTitle>YardDog</IonTitle>
          <IonButtons slot="end">
              <a>
                <img style={{height: "28px", marginRight: "15px", color: "white"}} onClick={() => ApiService.userLogout("borderInspections")} src={logoutIcon} alt="logout" />
              </a>
          </IonButtons>
        </IonToolbar> 
      </IonHeader>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toast}
        color="danger"
        duration={5000}
      />
      <IonModal isOpen={openMessageModal}>
          <IonItemGroup style={{ overflowY: "auto", paddingTop: "10%", background: "#dcdcdc"}}>
            { vehicle ? <IonItem lines="none" color="light">
              <div className="input-container">
                <input id="issues" className="input" type="text" placeholder="" value={selectVehicleId} onChange={e => setSelectedVehicleId(e.target.value!)}/>
                <p className="placeholder">ID #</p>
              </div>
            </IonItem> : null} 
            <IonItem color="light" lines="none">
                <div className="item-container" style={{ display: "flex", alignItems:"center", justifyContent: "space-between"}}>
                  <p>Notes:</p>
                  <IonIcon style={{ fontSize: "large"}} icon={close} onClick={() => {setOpenMessageModal(false); setComment("");}}></IonIcon>
                </div>
            </IonItem>
            <IonItem lines="none" color="light">
              <div className="input-container">
                <input id="issues" className="input" type="text" placeholder="" value={comment} onChange={e => setComment(e.target.value!)}/>
                <p className="placeholder">Comments</p>
              </div>
            </IonItem>
            <IonItem color="light" lines="none">
              <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)"}}>
                <div>
                  <p>Issues</p>
                  <select className="input" defaultValue={"None"} value={issue} onChange={(e) => {setIssue(e.target.value)}} style={{ height: "20px", width: "30vw"}}>
                    <option value={"None"}>None</option>
                    <option value={"Expired Annuals"}>Expired Annual</option>
                    <option value={"Tires"}>Tires</option>
                    <option value={"Long Term Issue"}>Long Term Issue</option>
                    <option value={"Other"}>Other</option>
                  </select>
                </div>
              </div>
              { selectedTerminal === 'nashville' || selectedTerminal === 'richmond' ?
              <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)"}}>
                <div>
                  <p>KOTL</p>
                  <select className="input" onChange={(e) => {setActiveKOTL(e.target.value)}} value={activeKOTL} style={{ height: "20px", width: "30vw"}}>
                    <option value={"false"}>No</option>
                    <option value={"true"}>Yes</option>
                  </select>
                </div>
              </div> :  null }
            </IonItem>
            <IonItem color="light" lines="none">
              <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)"}}>
                <div>
                  <p>Loaded</p>
                  <select className="input" onChange={(e) => {setLoaded(e.target.value)}} value={loaded} style={{ height: "20px", width: "30vw"}}>
                    <option value={"false"}>No</option>
                    <option value={"true"}>Yes</option>
                  </select>
                </div>
              </div>
            </IonItem>
            {checkedVehicle ?
            <IonItem lines="none" color="light">
              <div className="item-container">
                <button className="western-button submit" onClick={() => {setOpenMessageModal(false); setCheckedVehicle(false); addVehicle(selectVehicleId, selectedVehicleType, selectedTerminal, false, comment, issue, activeKOTL, loaded);}}>Add Vehicle</button>
              </div>
              <div className="item-container">
                <button className="western-button-cancel submit" onClick={() => {setOpenMessageModal(false); setCheckedVehicle(false); setComment("");}}>Cancel</button>
              </div>
            </IonItem>
            :
            <IonItem lines="none" color="light">
              <div className="item-container">
                <button className="western-button submit" onClick={() => {setOpenMessageModal(false); addMessageToVehicle(selectVehicleId, comment, vehicle, issue, activeKOTL, loaded)}}>Done</button>
              </div>
              <div className="item-container">
                <button className="western-button-cancel submit" onClick={() => {setOpenMessageModal(false); removeVehicle(vehicle)}}>Remove</button>
              </div>
            </IonItem>}
            </IonItemGroup>
      </IonModal>

      <IonModal isOpen={openAddModal}>
        <IonItemGroup style={{ overflowY: "auto", paddingTop: "10%", background: "#dcdcdc"}}>
          <IonItem color="light" lines="none">
              <div className="item-container" style={{ display: "flex", alignItems:"center", justifyContent: "space-between"}}>
                <h4>Add Vehicle:</h4>
                <IonIcon style={{ fontSize: "large"}} icon={close} onClick={() => {setOpenAddModal(false); setComment(""); setIssue(""); setActiveKOTL("false"); setLoaded("false");}}></IonIcon>
              </div>
          </IonItem>
          <IonItem color="light" lines="none">
            <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)"}}>
              <div>
                <p>Vehicle Type</p>
                <select className="input"  onChange={(e) => {setVehicleType(e.target.value)}} style={{ height: "20px", width: "30vw"}}>
                  <option value={"unit"}>Unit</option>
                  <option value={"trailer"}>Trailer</option>
                </select>
              </div>
            </div>
          </IonItem>
          <IonItem lines="none" color="light">
          <div className="input-container">
            <input id="issues" className="input" type="text" placeholder="" value={newIDVehicle} onChange={e => setNewIDVehicle(e.target.value!)}/>
            <p className="placeholder">ID #</p>
          </div>
          </IonItem>
          <IonItem lines="none" color="light">
            <div className="input-container">
              <input id="issues" className="input" type="text" placeholder="" value={comment} onChange={e => setComment(e.target.value!)}/>
              <p className="placeholder">Comments</p>
            </div>
          </IonItem>
          <IonItem color="light" lines="none">
            <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)"}}>
              <div>
                <p>Issues</p>
                <select className="input" defaultValue={"None"} value={issue} onChange={(e) => {setIssue(e.target.value)}} style={{ height: "20px", width: "30vw"}}>
                  <option value={"None"}>None</option>
                  <option value={"Expired Annuals"}>Expired Annual</option>
                  <option value={"Tires"}>Tires</option>
                  <option value={"Long Term Issue"}>Long Term Issue</option>
                  <option value={"Other"}>Other</option>
                </select>
              </div>
            </div>
            { selectedTerminal === 'nashville' ?
            <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)"}}>
              <div>
                <p>KOTL</p>
                <select className="input" onChange={(e) => {setActiveKOTL(e.target.value)}} value={activeKOTL} style={{ height: "20px", width: "30vw"}}>
                  <option value={"false"}>No</option>
                  <option value={"true"}>Yes</option>
                </select>
              </div>
            </div> :  null }
          </IonItem>
          <IonItem color="light" lines="none">
            <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)"}}>
              <div>
                <p>Loaded</p>
                <select className="input" onChange={(e) => {setLoaded(e.target.value)}} value={loaded} style={{ height: "20px", width: "30vw"}}>
                  <option value={"false"}>No</option>
                  <option value={"true"}>Yes</option>
                </select>
              </div>
            </div>
          </IonItem>
          <IonItem lines="none" color="light">
            <div className="item-container">
              <button className="western-button submit" onClick={() => {setOpenAddModal(false); addVehicle(newIDVehicle, vehicleType, selectedTerminal, true, comment, issue, activeKOTL, loaded);}}>Add Vehicle</button>
            </div>
          </IonItem>
        </IonItemGroup>
      </IonModal>

      <IonModal isOpen={showConfirmModal} cssClass='my-custom-class'>
        <IonCard>
          <IonCardHeader>
              <IonCardSubtitle>Confirm?</IonCardSubtitle>
              <IonCardTitle>Submit Yard Report?</IonCardTitle>
            </IonCardHeader>
          <IonButton id="western-button" style={{"--ion-color-primary": "#71FE83", "background-color": "#71FE83", color: "#4c8f2d"}} expand="block" onClick={ e => { createCSV(); submitReport(); checkKTOL(); setShowConfirmModal(false)}}>Confirm</IonButton>
          <IonButton id="western-button" expand="block" onClick={ e => { setShowConfirmModal(false)}}>Cancel</IonButton>
        </IonCard>
      </IonModal>

      <IonToast
        isOpen={successPhoto}
        onDidDismiss={() => {setSuccess(false)}}
        message="Your photos were submitted."
        color="success"
        duration={3000}
      />
       <IonToast
        isOpen={showErrorModal}
        message="Error! One or more photos could not be uploaded, check your connection and please try again."
        color="danger"
        duration={5000}
      />
      <IonContent color='white'>
        <div className='main-2'>
          <div style={{display: "grid", margin: "20px 20px 0px 20px"}}>
              <h1>Unit/Trailers</h1>
              <p style={{fontSize: "medium"}}><b>Select from the list of available trailers to assign to the currently selected terminal</b></p><br/>
          </div>
          <div className="item-container" style={{ display: "flex", alignItems: "center", gap: "min(3vw, 48px)", margin: "0px 25px 50px"}}>
              <div>
                  <select className="input" placeholder='None' onChange={(e) => {setSelectedTerminal(e.target.value); getVehiclesByTerminal(e.target.value); getLastReport(e.target.value);}} style={{ height: "20px", width: "auto", boxShadow: "1px 1px 4px 4px rgba(0,0,0,0.25)"}}>
                      <option value={"none"}>None</option>
                      <option value={"nashville"}>Nashville</option>
                      <option value={"bloomington"}>Bloomington</option>
                      <option value={"laredo"}>Laredo</option>
                      <option value={"bethlehem"}>Bethlehem</option>
                      <option value={"plainfield"}>Plainfield</option>
                      <option value={"richmond"}>Richmond</option>
                  </select>
              </div>
          </div>
          {/* /// FILTERED LIST */}
          { selectedTerminal !== "" ? <div>
          <div className="m-20" style={{display: "flex"}}>
            <div style={{width: "45%", height: "auto", margin: "15px"}}>
              <div style={{background: "#dcdcdc"}}>
                <IonSearchbar color="light" placeholder="Search..." animated={true} debounce={150} onIonChange={(e: any) => filterVehicles(e.detail.value)}></IonSearchbar>
              </div>
              <IonList style={{height: "50vh", overflowY: "scroll", borderRadius: "0px 0px 5px 5px"}}>
                {filteredUnits.map(function(unit){
                let unitList = 
                  <div style={{margin: "10px", borderRadius: "5px", padding: "20px", background: "#fff", textAlign: "center", display: "flex"}} onClick={e => {setOpenMessageModal(true); setCheckedVehicle(true); setSelectedVehicleId(unit.id); setIssue(unit.issue); setLoaded(unit.loaded); setActiveKOTL(unit.kotl); setComment(unit.message); setSelectedVehicleType('unit')}}>
                    <p style={{margin: "auto 10% auto 40%", fontSize: "20px", fontWeight: "600"}}>{unit.id}</p>
                    <div style={{margin: "0px 5%"}}><IonIcon style={{fontSize: "30px", color: "#fff"}} src={chatboxEllipsesOutline}/></div>
                  </div>
                return unitList;
                })}
                {filteredTrailers.map(function(trailer){
                  let trailerList = 
                    <div style={{margin: "10px", borderRadius: "5px", padding: "20px", background: "#fff", textAlign: "center", display: "flex"}} onClick={e => {setOpenMessageModal(true); setCheckedVehicle(true); setIssue(trailer.issue); setLoaded(trailer.loaded); setActiveKOTL(trailer.kotl); setComment(trailer.message); setSelectedVehicleId(trailer.id); setSelectedVehicleType('trailer')}}>
                      <p style={{margin: "auto 10% auto 40%", fontSize: "20px", fontWeight: "600"}}>{trailer.id}</p>
                      <div style={{margin: "0px 5%"}}><IonIcon style={{fontSize: "30px", color: "#fff"}} src={chatboxEllipsesOutline}/></div>
                    </div>
                  return trailerList;
                })}
              </IonList>
            </div>
            {/* /// SELECTED LIST */}
            <div style={{width: "45%", height: "auto", margin: "15px"}}>
              <div style={{background: "#dcdcdc"}}>
                <IonSearchbar color="light" placeholder="Search..." animated={true} debounce={150} onIonChange={(e: any) => filterSelectedVehicles(e.detail.value)}></IonSearchbar>
              </div>
              <IonList style={{height: "50vh", overflowY: "scroll", borderRadius: "0px 0px 5px 5px"}}>
                {filteredSelectedVehicles.map(function(vehicles){
                  let selectedList = 
                    <div style={vehicles.addedVehicle ? {margin: "10px", borderRadius: "5px", padding: "20px", background: "#ffff00", textAlign: "center", display: "flex"} : {margin: "10px", borderRadius: "5px", padding: "20px", background: "#fff", textAlign: "center", display: "flex"}}
                    onClick={e => {setOpenMessageModal(true); setComment(vehicles.message); setActiveKOTL(vehicles.kto); setIssue(vehicles.issue); setVehicle(vehicles); console.log("vehicles", vehicles);}}>
                      <p style={{margin: "auto 10% auto 40%", fontSize: "20px", fontWeight: "600"}}>{vehicles.id}</p>
                      <div style={{margin: "0px 5%"}}><IonIcon style={{fontSize: "30px", color: "#898989"}} src={chatboxEllipsesOutline}/></div>
                    </div>
                  return selectedList;
                })}
                <div className='item-container'>
                  <button className="western-button submit" onClick={e => {setOpenAddModal(true)}}>Add Vehicle</button>
                </div>
              </IonList>
            </div>
          </div>
          <IonItem lines="none" color="white">
            <div className='item-container'>
              <button className="western-button submit" style={{margin: "10px"}} disabled={selectedVehicles.length === 0} onClick={e => {setShowConfirmModal(true);}}>SUBMIT REPORT</button>
            </div>
          </IonItem>
          </div> : null }
        </div>
      </IonContent>
 
    </IonPage>
  );
};

export default YardDog;
