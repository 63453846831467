import React, {useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar} from '@ionic/react';
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import chevronRight from "../imgs/chevron-right.png";
import AnimateHeight from 'react-animate-height';
import { chevronDown, chevronUp } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { userStore } from '../api/AuthService';

const HelpDirect: React.FC = () => {

    const [height1, setHeight1] = useState(false);
    const [height2, setHeight2] = useState(false);
    const [height4, setHeight4] = useState(false);
    const [height7, setHeight7] = useState(false);

    const linkToDVIR = async () => {

    }



    return (
            <div>
            <IonList style={{background: "transparent"}}>
                <IonItem lines="none" style={{'--background': 'transparent'}}>
                    <IonLabel className="ion-text-wrap"><h1 style={{fontWeight: '500', textAlign: 'center', fontFamily: "Montserrat, sans-seriff"}}>What can we help you with?</h1></IonLabel>
                </IonItem>
                <IonButton id="training-btn" expand='block' className="training-btn" strong color="black" onClick={ e => setHeight1(!height1) } aria-expanded={ height1 !== false }><div className="training-text">Payment Advances</div><div className="training-text-back" style={{color: 'white'} }></div></IonButton>
                <AnimateHeight style={{ overflowY: 'scroll'}} duration={500} height={ height1 === true ? 'auto' : 0 } id="loadSection">
                    <IonCardHeader style={{backgroundColor: "transparent"}}>
                        <Link to="/personalAdvance" style={{textDecoration: "none"}}>
                            <div className="space-between link-lead">
                                <p style={{paddingLeft: "10px"}}><b>Cash Advance</b></p>
                                <img src={chevronRight} style={{ height: "1.8vh", paddingRight: '8px'}}alt="arrow"/>
                            </div>
                        </Link>
                        <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}>
                            <span style={{fontSize: "15px"}}>You can request a personal advance in the <span style={{color: "#666667"}}>Cash Advance</span><br/> page if the following conditions are met:</span><br/><br/>
                            <span style={{fontSize: "12px", lineHeight: '20px'}}>- You have money available for an advance<br/>- You are dispatched<br/>- You are up to date on paperwork.<br/></span><br/><br/>
                            <span style={{fontSize: "15px"}}>Have Other Questions?</span><br/><br/>
                            <span style={{fontSize: "12px", lineHeight: '20px'}}>- <Link to="/personalAdvance" style={{}}>How to create an EFS Account</Link><br/>- <Link to="/personalAdvance" style={{}}>How to get money from your account</Link></span>
                        </IonCardSubtitle>
                    </IonCardHeader>
                </AnimateHeight>

                <IonButton id="training-btn" className="training-btn" strong color="black" onClick={ e => setHeight2(!height2) } aria-expanded={ height2 !== false }><div className="training-text">Pre-planned Loads</div><div className="training-text-back" style={{color: 'white'} }></div></IonButton>
                <AnimateHeight style={{overflowY: 'scroll'}} duration={500} height={ height2 === true ? 'auto' : 0 } id="loadSection">
                <IonCardHeader style={{backgroundColor: "transparent"}}>
                    <Link to="/preplanned" style={{textDecoration: "none"}}>
                        <div className="space-between link-lead">
                            <p style={{paddingLeft: "10px"}}><b>Preplanned</b></p>
                            <img src={chevronRight} style={{ height: "1.8vh", paddingRight: '8px'}}alt="arrow"/>
                        </div>
                    </Link>
                    <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}>
                        <span style={{fontSize: "15px"}}>You can view your next preplanned load on the <span style={{color: "#666667"}}>Preplanned</span> page which<br/> will show all relavant details to the load.</span><br/><br/>
                    </IonCardSubtitle>
                    </IonCardHeader>
                </AnimateHeight>

                <IonButton id="training-btn" className="training-btn" strong color="black" onClick={ e => setHeight4(!height4) } aria-expanded={ height4 !== false }><div className="training-text">Need a Trailer</div><div className="training-text-back" style={{color: 'white'} }></div></IonButton>
                <AnimateHeight style={{overflowY: 'scroll'}} duration={500} height={ height4 === true ? 'auto' : 0 } id="loadSection">
                <IonCardHeader style={{backgroundColor: "transparent"}}>
                    <Link to="/trailertracking" style={{textDecoration: "none"}}>
                        <div className="space-between link-lead">
                            <p style={{paddingLeft: "10px"}}><b>Trailer Tracking</b></p>
                            <img src={chevronRight} style={{ height: "1.8vh", paddingRight: '8px'}}alt="arrow"/>
                        </div>
                    </Link>
                    <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>If you have a trailer assinged to you, you can view its location from the <span style={{color: "#666667"}}>Trailer Tracking</span> page or request a new trailer.</span></IonCardSubtitle>
                    </IonCardHeader>
                </AnimateHeight>

                <IonButton id="training-btn" className="training-btn" strong color="black" onClick={ e => setHeight7(!height7) } aria-expanded={ height7 !== false }><div className="training-text">Truck / Trailer issues</div><div className="training-text-back" style={{color: 'white'} }></div></IonButton>
                <AnimateHeight style={{overflowY: 'scroll'}} duration={500} height={ height7 === true ? 'auto' : 0 } id="loadSection">
                <IonCardHeader style={{backgroundColor: "transparent"}}>
                    <Link to="/safety/contacts" style={{textDecoration: "none"}}>
                        <div className="space-between link-lead">
                            <p style={{paddingLeft: "10px"}}><b>Safety Contacts</b></p>
                            <img src={chevronRight} style={{ height: "1.8vh", paddingRight: '8px'}}alt="arrow"/>
                        </div>
                    </Link>
                    <IonCardSubtitle style={{color:'black', padding:'15px', inlineSize: "320px", overflowWrap: "break-word"}}><span style={{fontSize: "15px"}}>If you are having issues with your unit/trailer please fill out a DVIR out in the platform science tablet.</span></IonCardSubtitle>
                    </IonCardHeader>
                </AnimateHeight>
            </IonList>
            <div>

            </div>
            </div>
    )
};

export default HelpDirect;
