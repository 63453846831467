export function deliverError (errcode) {
    let messageBase = 'System Error.'
    switch (errcode) {
        case 'BADARG':
            messageBase = 'Not enough info.'
            break;
        case 'BADARGS':
            messageBase = 'Not enough info.'
            break;
        case 'BADLOGIN':
            messageBase = 'Incorrect username or password.'
            break;
        case 'BADPWRESET':
            messageBase = 'Password reset failed.'
            break;
        case 'BADTYPE':
            messageBase = 'Invalid input.'
            break;
        case 'DBSYSERR':
            messageBase = 'System Error.'
            break;
        case 'DELETEDUSER':
            messageBase = 'User does not exist.'
            break;
        case 'DISABLEDUSER':
            messageBase = 'User does not exist.'
            break;
        case 'DUPUSER':
            messageBase = 'Duplicate user data'
            break;
        case 'EFSFAIL':
            messageBase = 'EFS Card not accepted.'
            break;
        case 'MISSINGARGS':
            messageBase = 'Not enough info.'
            break;
        case 'NOCARD':
            messageBase = 'No card info.'
            break;
        case 'NODISPATCH':
            messageBase = 'No dispatched driver.'
            break;
        case 'NOREQARG':
            messageBase = 'Not enough info.'
            break;
        case 'NOTFOUND':
            messageBase = 'not found.'
            break;
        case 'NOTLOGGED':
            messageBase = 'Not logged in.'
            break;
        case 'NOTOKEN':
            messageBase = 'Session Expired.'
            break;
        case 'NOTREADY':
            messageBase = 'Unit unavailable.'
            break;
        case 'NOTSEATED':
            messageBase = 'Unit unseated.'
            break;
        case 'REJECTED':
            messageBase = 'Request rejected.'
            break;
        default:
            console.log('UNKNOWN ERROR.')
            break;
    }
    return messageBase
}

const coreValues = [
    {
        value: "BE DRIVER FRIENDLY",
        desc: "Every associate at Western Express will work towards the goal of being the “driver friendliest” company in America. Our drivers are the core of our company. If the trucks aren’t seated – none of us will receive a paycheck. Drivers are the reason we are here. They will be treated with respect, understanding, and empathy. To reach our goal of the #1 driver friendly company in America, we are establishing a DRIVER FRIENDLY COMMITTEE. There will be representatives from every area of the company on the committee. This group will meet regularly and brainstorm for ideas on how to set and monitor driver treatment."
    },
    {
        value: "EXPECT EXCELLENCE",
        desc: "There were many suggestions that rolled up into this core value. For example: measure everything, hold each other accountable, have a sense of urgency, adapt and overcome. We, as a company, have high expectations for each team member. Each team member has high expectations for the company and everyone else on the team. Always give and expect excellence."
    },
    {
        value: "HAVE FUN",
        desc: "This encompasses: Have Fun & Play Together, build strong relationships, be a part of the team, we are family. Everyone here has a hard job. We work hard, but it is important that we take time to have fun."
    },
    {
        value: "THINK BIG",
        desc: "The basis of this core value: don’t be afraid to fail, be innovative, try something new, look for innovative solutions, ask why, think outside the box, and take calculated risks. If we think small, we will never succeed."
    },
    {
        value: "BE HUMBLE, HUNGRY, & HARDWORKING",
        desc: "While this statement has been made by many over the years, I am giving credit to DeWayne “The Rock” Johnson. His actual quote was “be humble, be hungry, and always be the hardest worker in the room”. Our industry historically ebbs and flows, during the good times we cannot get complacent – we must stay hungry and work hard – because the tides will turn."
    },
    {
        value: "DO MORE WITH LESS",
        desc: "Many of the suggested values said exactly what this is: be frugal, be a good steward of Western’s money, spend wisely, look at all expenses like you are paying with your own money. To be successful, we must watch our expenditures. Spend when necessary or when it improves the company."
    },
    {
        value: "BE SAFE",
        desc: "Safety is and will remain a big focus. Safety, “by all means available”, including assessment, training, education, technology, and equipment."
    },
    {
        value: "STAY FIT",
        desc: "We will maintain a culture of fitness of mind & body. It is important that you take care of yourself first, so that you can take care of others."
    },
    {
        value: "TAKE CARE OF EACH OTHER",
        desc: "Always consider others, think “us” before “me”, take care of our drivers, associates, customers and vendors."
    }

]

export function coreValueOfTheDay (selected) {
    return coreValues[selected]
}

const processedKeywords = (array) => {
    let filtered = array.filter(el => el && !el.includes('\n'))
    let temp = []
    filtered.forEach(text => {
        let words = text.split(' ')
        words.forEach(word => {
            temp.push(word.toLocaleUpperCase())
        })
    })
    return temp
}

const loadKeywords = [ 'bl','proof', 'bol', 'pod', '\n    ', 'Current Load', '', 'Load Number', 'Dispatch Number', 'Number of Stops', 'Load At', 'Load At Address', 'Load At Address 2', 'Load At Phone Number', 'Pickup Date', ' - ', 'BL Number', 'Delivery Number', 'Pickup Number', 'Lumper Code', '***Preloaded or Current Trailers***', 'Trailer 1', 'Consignee', 'Consignee Address', 'Consignee Address 2', 'Consignee Phone Number', 'Consignee Contact', 'Delivery Date', ' - ', 'Driver Load', 'N', 'Driver Unload', 'N', 'Loaded Miles', 'Empty Miles', 'Total Miles', 'Tarp Photos Recieved', 'Not Required', '***Comments***', '', '', '', '', '\n  ', '\n\n\n', 'preplan', 'preplanned', 'plan', 'pre-plan', 'pre-planned']
const tripKeywords = ['map', 'track', 'tracking', 'find', 'locate', 'trailer', 'request', 'find', 'my', 'report', 'number', 'trip', 'tarp', 'photos', 'pictures', 'rear', 'side', 'front', 'transflo', 'transflow', 'paperwork', 'file', 'scan', 'trans', 'passenger', 'add', 'non-driver', 'lp', 'lease', 'purchase', 'program', 'vehicle', 'inspection', 'new', 'truck', 'tractor', 'checklist', 'pretrip', 'breakdown', 'roadside', 'service', 'inspection']
const statsKeywords = ['stats', 'Miles', 'Rank', 'Week', 'Month', 'All Time', 'leader', 'board', 'leaderboard', 'standing', 'Ranking', 'MPG', 'Pretrip', 'Score', 'leaders', 'lead', 'division', 'minimum', 'loads', 'place']
const moneyKeywords = ['check','checks','money','paychecks', 'cash advance', 'personal', 'referral', 'refferal', 'referal', 'referall', 'refferral', 'refferrall', 'personel', 'personle', 'pay', 'payroll', "Paycheck", "Net Amount", "$", "Earnings", "Taxes", "Reimbursements","Deductions", 'tax', 'earn', 'earning', 'reimburse', 'deduct', 'paid', 'efs', 'adp', 'contracts', 'lfx', 'lfn', 'agreement', 'agreements']
const docsKeywords = ['guide', 'safety', 'packet', 'cargo', 'securement', 'handbook', 'documents', 'document', 'doc', 'docs']
export const paychecks = /^([a-zA-Z0-9_-]){7}$/;
export const loadSearch = processedKeywords(loadKeywords)
export const tripSearch = processedKeywords(tripKeywords)
export const statsSearch = processedKeywords(statsKeywords)
export const moneySearch = processedKeywords(moneyKeywords)
export const docsSearch = processedKeywords(docsKeywords)

export const padLength = (str, length) => {
  if (typeof str !== "string") {
    str = str + "";
  }
  let outStr = str;
  while (outStr.length < length) {
    outStr = "0" + outStr;
  }
  return outStr;
};






