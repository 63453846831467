import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";

const NewTrainee: React.FC = () => {

    const [traineeCode, setTraineeCode] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [showReleaseModel, setShowReleaseModel] = useState(false);
    const [activeTraineesCount, setActiveTraineesCount] = useState<number>(0)

    useEffect(() => {
        checkClaimed();
    }, [])

    function checkClaimed() {
        ApiService.getTrainees("newTrainee", { released: 0}).then(it => {
            console.log("trainees", it.length);
            setActiveTraineesCount(it.length)
        });
    }

    function claimTrainee() {
        ApiService.claimTrainee("newTrainee", {traineeCode: traineeCode.toUpperCase()}).then(() => {
            setShowModal1(false);
            setShowModal(true);
        }).finally(() => setShowModal1(false));
    }

    const handleClaim = () => {
        setTraineeCode(traineeCode.trim())
            if (traineeCode.length > 6) {
                toastStore.getState().setToast('Trainee codes cannot be longer than 6 characters.');
                setShowModal1(false);
            } else {
                claimTrainee();
            }
    }

    return (
        <IonPage>
            <IonContent color="light">
            <IonModal isOpen={showModal} cssClass='my-custom-class'>
            <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='subtitle'>Successful Trainee Assignment</p>
                        <p className='title'>{traineeCode}</p>
                        <IonButton id="western-button" expand="block" href="/trainingday" onClick={ function() { setShowModal(false); setShowModal1(false);}}>Done</IonButton>
                    </div>
                </IonItem>
            </IonModal>

            <IonModal isOpen={showReleaseModel} cssClass='my-custom-class'>
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='subtitle'>You already have an active trainee</p>
                        <p className='title'>Do you want to release your currently active trainee?</p>
                        <IonButton className="western-button" style={{"--background": "#71FE83"}} expand="block" href='/releasetrainee'>Yes</IonButton>
                        <IonButton className="western-button" style={{"--background": "#71FE83"}} onClick={ function() { setShowReleaseModel(false); claimTrainee();}}>No</IonButton>
                    </div>
                </IonItem>
            </IonModal>

            <IonModal isOpen={showModal1} cssClass='my-custom-class'>
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <p className='subtitle'>Confirm</p>
                        <p className='title'>Assign yourself to trainee: {traineeCode}?</p>
                        <IonButton className="western-button submit" style={{"--background": "#71FE83"}} onClick={ function() { handleClaim();}}>CONFIRM</IonButton>
                        <IonButton className="western-button" style={{"--background": "#71FE83"}} onClick={ function() { setShowModal1(false)}}>Cancel</IonButton>
                    </div>
                </IonItem>
            </IonModal>

            <IonHeader>
                <IonToolbar color='primary'>
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/Home" text=""/>
                </IonButtons>
                <IonTitle>New Trainee</IonTitle>
                </IonToolbar>
            </IonHeader>
                <IonItem lines="none" color="light">
                    <div className='item-container'>
                        <div className="input-container">
                            <input id="trainee" className="input" type="text" placeholder=" " value={traineeCode} onChange={e => setTraineeCode(e.target.value!)}/>
                            <p className="placeholder">Trainee Code</p>
                        </div>
                        <button className="western-button submit" onClick={() => setShowModal1(true)}>CLAIM</button>
                    </div>


                </IonItem>
            </IonContent>
        </IonPage>
    )
};

export default NewTrainee;
