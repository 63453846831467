import React, { useState } from 'react';
import './ExploreContainer.css';
import trailer from '../imgs/truck.png'
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';


const TrailerMarker = (props: any) => {
    const [infowindowOpen, setInfowindowOpen] = useState(false);
    const [markerRef, marker] = useAdvancedMarkerRef();

    return <div><AdvancedMarker position={{lat: props.element.latitude, lng: props.element.longitude}} ref={markerRef} onClick={() => setInfowindowOpen(!infowindowOpen)}>
      <img src={trailer} id='' style={{width: "35px"}}></img>
    </AdvancedMarker>
    {infowindowOpen && (
      <InfoWindow
        anchor={marker}
        maxWidth={300}
        style={{color: "black", fontWeight: "600"}}
        onCloseClick={() => setInfowindowOpen(!infowindowOpen)}
        >
        <div>
          <span>Trailer #: {props.element.trailerId}</span><br/>
          <span>Reported: {props.element.lastPing}</span>
        </div>
        <div style={{margin: "10px 45px"}}>
          <button className='western-button' onClick={() => {props.onSelect(props.element); setInfowindowOpen(false)}}>Select Trailer</button>
        </div>
      </InfoWindow>
    )}
    </div>
};

export default TrailerMarker;
