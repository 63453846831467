import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import PrePlannedEntry from './PrePlannedEntry';
import chevronRight from "../imgs/chevron-right.png"
import {ApiService} from "../api/ApiService";
import {PreplannedLoad} from "../api/model/PreplannedLoad";
import {Link} from "react-router-dom";

var moment = require('moment');

const PrePlanned: React.FC = () => {

    const [loads, setLoads] = useState<PreplannedLoad[]>();
    const [showNoLoads, setShowNoLoads] = useState(false)

    useEffect(() => {
        getLoads();
    }, []);

    function getLoads() {
      let tempLoad:PreplannedLoad[] = []
        ApiService.getPreplannedLoads("prePlanned").then(it => {
            it.forEach(load => {
              load.deliveryStartDate = moment([((load.deliveryStartDate).toString()).slice(0, 4), 0, 1]).add(((load.deliveryStartDate - 1).toString()).slice(4), 'days').format('M/D/YYYY');
              load.deliveryEndDate = moment([((load.deliveryEndDate).toString()).slice(0, 4), 0, 1]).add(((load.deliveryEndDate - 1).toString()).slice(4), 'days').format('M/D/YYYY');
              load.pickupStartDate = moment([((load.pickupStartDate).toString()).slice(0, 4), 0, 1]).add(((load.pickupStartDate - 1).toString()).slice(4), 'days').format('M/D/YYYY');
              load.pickupEndDate = moment([((load.pickupEndDate).toString()).slice(0, 4), 0, 1]).add(((load.pickupEndDate - 1).toString()).slice(4), 'days').format('M/D/YYYY');
              tempLoad.push(load);
            });
            setShowNoLoads(false);
            setLoads(tempLoad);
        }).catch(() => {
            setShowNoLoads(true);
        });
    }

  return (
    <IonPage>
    <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Pre-Planned Loads</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
            {loads?.map(function(load, index){
                  return  <PrePlannedEntry key={index} load={load} getLoads={getLoads}/>
              })}
                  
     
       {showNoLoads ? <IonItem lines="none" color="light"><div className='item-container'><p>No pre-planned loads?</p></div></IonItem> : ''}
       {showNoLoads ? <IonItem lines="none" color="light">
              <div className='item-container'>
        <p className='title'>Plan your loads.</p>
        <p className='subtitle'>Lets get you another load. Go to the Help section and contact dispatch to get back on the road.</p>
        <Link to="/Support" className="western-button-link">
        <div className="green" id="current-load-button">
                <p>Help</p>
                <img src={chevronRight} style={{ height: "1.8vh"}}alt="arrow"/>
            </div>
        </Link>
        </div>
      </IonItem> : ''}
      </IonContent>
    </IonPage>
  );
};

export default PrePlanned;
