import React, {useEffect, useState} from "react"
import {IonItem, IonModal} from "@ionic/react"
import keyIcon from "../imgs/key.png"
import chainIcon from "../imgs/chain.png"
import callIcon from "../imgs/call.png"
import safeIcon from "../imgs/safety.png"
import helpdeskIcon from "../imgs/helpdesk.png"
import ContactCard from "./ContactCard"
import {ApiService} from "../api/ApiService";
import {DriverManager} from "../api/model/DriverManager";
import {Link} from "react-router-dom";
import {userStore} from "../api/AuthService";
import { send } from "ionicons/icons"

const Contacts = () => {
    const [showDM, setShowDM] = useState(false)
    const [dm, setDM] = useState<DriverManager>({} as DriverManager)

    useEffect(() => {
        if (userStore.getState().driver?.dmCode) {
            ApiService.getDriverManager("contacts", {dmCode: userStore.getState().driver.dmCode}).then(it => {
                if (it) {
                    setDM(it);
                }
            });
        }
    }, []);

    const handleDMContact = () => {
        ApiService.sendEventCommand("Opend DM info", "eventCheck", "User viewed their DM info");
        setShowDM(true);
    }

    return (
        <IonItem lines="none" color="light">
            <IonModal isOpen={showDM} id="transparent">
                <div className="modal-div" onClick={() => setShowDM(false)}>
                    <div className="item-container" id="modal">
                        <ContactCard contact={{name: dm.name, email: dm.email, subcategory: "Driver Manager", phone: dm.phone}}/>
                    </div>
                </div>
            </IonModal>
            <div className="item-container">
            <div style={{ margin: "8px"}} className="grid50">
                    <h5>More Questions?</h5>
                    <h5 style={{ color: "#5096FF", textAlign: "center"}} onClick={()=> {handleDMContact()}}>View Manager Info</h5>
                </div>
                <div className="grid50">
                    <Link to="/keycontacts" className="western-button-link">
                        <div>
                            <img src={keyIcon} alt="key" className="link-icon"/><p>Key Contacts</p>
                        </div>
                    </Link>
                    <a href={`tel:${dm.phone}`} className="western-button-link">
                        <div>
                            <img src={callIcon} alt="key" style={{ height: "min(5vw, 22px)", margin: '4px', backgroundColor: "#69E175", padding: "4px", borderRadius: "50%"}}/><p>Call DM</p>
                        </div>
                    </a>
                </div>
                <div className="grid50">
                    <Link to="/chainofcommand" className="western-button-link">
                        <div>
                            <img src={chainIcon} alt="key" className="link-icon"/><p>Chain of Contact</p>
                        </div>
                    </Link>
                    <Link to="/safety/contacts" className="western-button-link">
                        <div>
                            <img src={safeIcon} alt="key" className="link-icon"/><p>Safety Contact</p>
                        </div>
                    </Link>
                </div>
                <Link to="/helpdesk" className="western-button-link">
                    <div className="green">
                        <img src={helpdeskIcon} alt="key" style={{ height: "min(10vw, 44px)", margin: '4px'}}/><p>Help Desk</p>
                    </div>
                </Link>
            </div>
        </IonItem>
    )
    
}
export default Contacts
