import React, {useEffect, useState} from "react";
import {IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {ApiService} from "../api/ApiService";
import {SafetyScorecardInfo} from "../api/model/SafetyScorecardResponse";
import {UserState, userStore} from "../api/AuthService";
import {DriverProfile} from "../api/model/DriverProfile";
import {DateUtils} from "../utils/DateUtils";

const SafetyScore: React.FC = () => {

    const {driver}: { driver: DriverProfile } = userStore((state: UserState) => ({driver: state.driver}));

    const [model, setModel] = useState<SafetyScorecardInfo>();

    useEffect(() => {
        ApiService.safetyScorecard('SafetyScore', {}).then(it => setModel(it));
    }, []);

    const formatPercent = (value?: string): string => {
        if (!value) {
            return '0.00 %';
        }

        return Number(value).toFixed(2) + ' %';
    }

    return <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/Home"/>
                </IonButtons>
                <IonTitle>Safety Score</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color="light">
            <IonCard color="light" className="px-4">
                <h1 className="pb-2">Driver Scorecard</h1>
                <p><b>Driver:</b> {driver.name} | {model?.DRCODE}</p>
                <p><b>Hire Date:</b> {DateUtils.formatDate(model?.HIREDATE)}</p>
                {/* <p><b>SSN:</b> {model?.SSN}</p> */}

                <div className="d-flex mt-3">
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">Score</div>
                        <div className="safety-score-section-body">{model?.SCORE}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">Training tenure</div>
                        <div className="safety-score-section-body">{model?.TRAINING_TENURE}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">DOT accidents</div>
                        <div className="safety-score-section-body">{model?.DOT_ACCIDENTS}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">Non DOT accidents</div>
                        <div className="safety-score-section-body">{model?.NON_DOT_ACCIDENTS}</div>
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">Roll Stability</div>
                        <div className="safety-score-section-body">{model?.RS}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">Lane Violation</div>
                        <div className="safety-score-section-body">{model?.LV}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">Extreme Brake</div>
                        <div className="safety-score-section-body">{model?.EB}</div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">F-D Violation</div>
                        <div className="safety-score-section-body">{model?.FD}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">JK Stability</div>
                        <div className="safety-score-section-body">{model?.JK}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">Aim High Steering</div>
                        <div className="safety-score-section-body">{model?.AHS}</div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">SLS CNT</div>
                        <div className="safety-score-section-body">{model?.SLS_CNT}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">SLS</div>
                        <div className="safety-score-section-body">{model?.SLS}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">AGD CNT</div>
                        <div className="safety-score-section-body">{model?.AGD_CNT}</div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">AGD</div>
                        <div className="safety-score-section-body">{model?.AGD}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">UA CNT</div>
                        <div className="safety-score-section-body">{model?.UA_CNT}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">UA</div>
                        <div className="safety-score-section-body">{model?.UA}</div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">HOS COUNT</div>
                        <div className="safety-score-section-body">{model?.HOS_COUNT}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">HOS</div>
                        <div className="safety-score-section-body">{formatPercent(model?.HOS_PERCENT)}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">HOS POINTS</div>
                        <div className="safety-score-section-body">{formatPercent(model?.HOS_POINTS)}</div>
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">TOTAL INSP POINTS</div>
                        <div className="safety-score-section-body">{model?.TOTAL_INSP_POINTS}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">RISK SCORE</div>
                        <div className="safety-score-section-body">{model?.RISK_SCORE}</div>
                    </div>
                    <div className="safety-score-section">
                        <div className="safety-score-section-header">MVR CDL STATUS</div>
                        <div className="safety-score-section-body">{model?.MVR_CDL_STATUS}</div>
                    </div>
                </div>
            </IonCard>
        </IonContent>
    </IonPage>
}
export default SafetyScore;
