import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, IonTextarea, IonTitle, IonToolbar} from '@ionic/react';
import {ApiService} from "../api/ApiService";
import {Trainee} from "../api/model/Trainee";
import {toastStore} from "../components/AppToast";
import {TrainerFeedbackRequest, TrainerFeedbackRequestUtils} from "../api/model/request/TrainerFeedbackRequest";
import placeholder from "../imgs/placeholder_profile.png";
import {userStore} from "../api/AuthService";
import {TrainingCourse} from "../api/model/TrainingCourses";
import {useHistory} from "react-router-dom";

const TrainerFeedback: React.FC = () => {

    const history = useHistory();
    const [model, setModel] = useState<TrainerFeedbackRequest>(new TrainerFeedbackRequest());
    const [trainee, setTrainee] = useState<Trainee>();
    const [course, setCourse] = useState<TrainingCourse>();

    useEffect(() => {
        ApiService.getTraineeProfile("trainerFeedback").then(it => {
            if (it.currentCourseId) {
                ApiService.getTrainingCourse("trainerFeedback", {id: it.currentCourseId}).then(it => setCourse(it)).finally(() => updateTrainee(it));
            } else {
                updateTrainee(it);
            }
        });
    }, []);

    const updateTrainee = (trainee: Trainee) => {
        model.traineeCode = trainee.traineeCode;
        model.trainerCode = trainee.trainerCode;
        setTrainee(trainee);
    }

    const submit = () => {
        if (!TrainerFeedbackRequestUtils.isValid(model)) {
            toastStore.getState().setToast('Please fill all required fields.');
            return;
        }
        ApiService.submitTrainerFeedback("trainerFeedback", model).then(() => {
            toastStore.getState().setSuccessToast('Your trainer feedback report has been submitted.');
            history.push('/home');
        });
    }

    const getRadioRatingValues = () => <>
        <IonItem color="light" lines="none"><IonRadio value={1}></IonRadio><IonLabel className="ps-2">1 - Very Poor</IonLabel></IonItem>
        <IonItem color="light" lines="none"><IonRadio value={2}></IonRadio><IonLabel className="ps-2">2 - Poor</IonLabel></IonItem>
        <IonItem color="light" lines="none"><IonRadio value={3}></IonRadio><IonLabel className="ps-2">3 - Normal</IonLabel></IonItem>
        <IonItem color="light" lines="none"><IonRadio value={4}></IonRadio><IonLabel className="ps-2">4 - Good</IonLabel></IonItem>
        <IonItem color="light" lines="none"><IonRadio value={5}></IonRadio><IonLabel className="ps-2">5 - Very Good</IonLabel></IonItem>
    </>;

    const getRadioBooleanValues = () => <>
        <IonItem color="light" lines="none"><IonRadio value={1}></IonRadio><IonLabel className="ps-2">Yes</IonLabel></IonItem>
        <IonItem color="light" lines="none"><IonRadio value={0}></IonRadio><IonLabel className="ps-2">No</IonLabel></IonItem>
    </>;

    const handleModelChange = (e: any) => setModel({...model, [e.target.name]: e.target.value});

    return <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonBackButton defaultHref="/Home"/>
                </IonButtons>
                <IonTitle>Trainer Feedback</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen color="light">
            {trainee && <>
                <IonCard color="light">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6 d-flex border-top border-2 flex-row-reverse flex-lg-row ps-4">
                            <img className="profile-photo profile-photo-sm mt-4 me-4" onError={({currentTarget}) => {
                                currentTarget.onerror = null;
                                currentTarget.src = placeholder
                            }} src={userStore.getState().profilePictureUrl} alt="avatar"/>
                            <div className="w-100">
                                <h1>Trainee Info</h1>
                                <p><b>Code:</b> {trainee.traineeCode}</p>
                                <p><b>Name:</b> {trainee.traineeName}</p>
                                {course
                                    ? <>
                                        <p><b>Course:</b> {course.title}</p>
                                        <p><b>Course progress in days:</b> {trainee.currentCourseDay} / {course.totalDays} ({((trainee.currentCourseDay / course.totalDays) * 100).toFixed(0)}%)</p>
                                    </>
                                    : <p><b>Course:</b> <span className="color-danger">No course assigned.</span></p>
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 border-start border-top border-2 ps-4">
                            <h1>Trainer Info</h1>
                            <p><b>Code:</b> {trainee.trainerCode}</p>
                            <p><b>Name:</b> {trainee.trainerName}</p>
                        </div>
                    </div>
                </IonCard>
                <IonCard color="light">
                    <div className="row p-4">
                        <div className="d-flex justify-content-between align-items-center flex-wrap py-2"><h1 className="mt-0">Driver trainer feedback</h1>
                            <div><span className="color-danger">*</span> required fields</div>
                        </div>
                        <p>Please rate each of the following questions regarding trainer performance and training experience</p>
                        <IonRadioGroup name="overallScore" className="border-top border-2" value={model.overallScore} onIonChange={e => handleModelChange(e)}>
                            <h5>Rate overall experience on the trainer's truck <span className="color-danger">*</span></h5>{getRadioRatingValues()}
                        </IonRadioGroup>
                        <IonRadioGroup name="cleanliness" className="border-top border-2" value={model.cleanliness} onIonChange={e => handleModelChange(e)}>
                            <h5>Rate cleanliness of inside the truck <span className="color-danger">*</span></h5>{getRadioRatingValues()}
                        </IonRadioGroup>
                        <IonRadioGroup name="policyScore" className="border-top border-2" value={model.policyScore} onIonChange={e => handleModelChange(e)}>
                            <h5>Trainer knowledge of Western Express policies <span className="color-danger">*</span></h5>{getRadioRatingValues()}
                        </IonRadioGroup>
                        <IonRadioGroup name="conductScore" className="border-top border-2" value={model.conductScore} onIonChange={e => handleModelChange(e)}>
                            <h5>Rate professionalism of trainer <span className="color-danger">*</span></h5>{getRadioRatingValues()}
                        </IonRadioGroup>
                        <IonRadioGroup name="logTraining" className="border-top border-2" value={model.logTraining} onIonChange={e => handleModelChange(e)}>
                            <h5>Rate effectiveness of log training <span className="color-danger">*</span></h5>{getRadioRatingValues()}
                        </IonRadioGroup>
                        <IonRadioGroup name="overallExp" className="border-top border-2" value={model.overallExp} onIonChange={e => handleModelChange(e)}>
                            <h5>Rate overall training experience <span className="color-danger">*</span></h5>{getRadioRatingValues()}
                        </IonRadioGroup>
                    </div>
                </IonCard>
                <IonCard color="light">
                    <div className="row p-4">
                        <div className="d-flex justify-content-between align-items-center flex-wrap py-2"><h1 className="mt-0">Overall experience feedback</h1>
                            <div><span className="color-danger">*</span> required fields</div>
                        </div>
                        <p>Please rate each of the following questions regarding overall experience with Western Express</p>
                        <IonRadioGroup name="refWEXP" className="border-top border-2" value={model.refWEXP} onIonChange={e => handleModelChange(e)}>
                            <h5>Would you recommend trainer to other trainees? <span className="color-danger">*</span></h5>{getRadioBooleanValues()}
                        </IonRadioGroup>
                        <IonRadioGroup name="refTrainer" className="border-top border-2" value={model.refTrainer} onIonChange={e => handleModelChange(e)}>
                            <h5>Would you recommend Western Express to other drivers? <span className="color-danger">*</span></h5>{getRadioBooleanValues()}
                        </IonRadioGroup>
                        <IonRadioGroup name="dotReg" className="border-top border-2" value={model.dotReg} onIonChange={e => handleModelChange(e)}>
                            <h5>Does the training reflect the importance of DOT Rules & Regulation(s)? <span className="color-danger">*</span></h5>{getRadioBooleanValues()}
                        </IonRadioGroup>
                        <IonRadioGroup name="complaint" className="border-top border-2" value={model.complaint} onIonChange={e => handleModelChange(e)}>
                            <h5>At any point, did the trainer cause you to feel uncomfortable? <span className="color-danger">*</span></h5>{getRadioBooleanValues()}
                        </IonRadioGroup>
                    </div>
                </IonCard>
                <IonCard color="light">
                    <div className="row p-4">
                        <h1>Additional comments</h1>
                        <IonTextarea name="comments" value={model.comments} onIonChange={e => handleModelChange(e)} maxlength={200} cols={56} placeholder={'Max of 200 Characters ...'}></IonTextarea>
                    </div>
                </IonCard>
                <div className="pe-3 ps-2 pt-2 pb-5">
                    <button className="app-btn app-btn-primary w-100 d-block text-center" onClick={() => submit()}>SUBMIT</button>
                </div>
            </>}
        </IonContent>
    </IonPage>
}

export default TrainerFeedback;

