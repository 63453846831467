import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonButton, IonButtons, IonBackButton, IonItem } from '@ionic/react';
import {Link} from "react-router-dom";


const Payroll: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
      <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Drive" />
          </IonButtons>
          <IonTitle>Payroll</IonTitle>
        </IonToolbar>
      </IonHeader>
   
          <IonButton id="western-button" expand="block" href="/Drive/payroll/paychecks"><div id="btn-text">Paychecks</div></IonButton>
        <IonItem lines="none">
          <div className='item-container'>
          <div style={{ display: "flex", justifyContent: "space-between"}}>
            <h5>More Questions?</h5>
            <Link to="/Drive/payroll/payrollfaq" style={{ textDecoration: "none"}}><h5 style={{ color: "#5096FF", textAlign: "right"}}>FAQ</h5></Link>
          </div>
         
          </div>
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export default Payroll;
