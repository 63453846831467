import {getPlatforms} from "@ionic/core";
import React from "react";
import {config, version} from "../Constants"

export const sendErrorMsg = (msg: string) => {
    if (!config.discordErrorHandlerEnabled) {
        return;
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ content: msg + ", version " + version  + ", Driver Code: " + localStorage.getItem('username')?.toUpperCase() + ", Platform " + getPlatforms()})
    };
    fetch('https://discord.com/api/webhooks/1042834349671849984/a4CCySnRD9pQFbCTGU98II-CiUdmQCP-E3xb00UWh9G5pMITZgQp-L9lApvRJgqhi--Z', requestOptions)
}

