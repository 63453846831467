export enum UserType {
    ADMIN = 'admin',
    INSPECTOR = 'inspector',
    DRIVER_MANAGER = 'driver_manager',
    DRIVER = 'driver',
    TRAINEE = 'trainee',
    YARDDOG = 'yarddog',
    GUARDDOG = 'guarddog',
    INSTRUCTOR = 'instructor'
}
