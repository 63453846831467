import React from "react"
import advanceIcon from "../imgs/advance.png"
import payIcon from "../imgs/payroll.png"
import referIcon from "../imgs/refer.png"
import faqIcon from "../imgs/payroll_faq.png"
import documentIcon from "../imgs/document.png"
import Search from "./Search"
import {Link} from "react-router-dom";

const Money = () => {
    return (
        <div className="item-container">
            {/* <div>
                <Search onChange={handleSearch} featured={""}/> : null
            </div> */}
            <div className="grid50">
            <Link to="/paychecks"className="western-button-link">
                    <div>
                        <img src={payIcon} alt="key" className="link-icon"/><p>Paychecks</p>
                    </div>
                </Link>
                <Link to="/personaladvance" className="western-button-link">
                    <div>
                        <img src={advanceIcon} alt="key" className="link-icon"/><p>Cash Advance</p>
                    </div>
                </Link>
                <Link to="/referral" className="western-button-link">
                    <div>
                        <img src={referIcon} alt="key" className="link-icon"/><p>Personal Referral<br/><span style={{ color: "#D0A024", fontSize: "12px"}}>$500 After 1 mo</span></p>
                    </div>
                </Link>
                <Link to="/payrollfaq" className="western-button-link">
                    <div>
                        <img src={faqIcon} alt="key" className="link-icon"/><p>Payroll FAQ</p>
                    </div>
                </Link>
            </div>
            <div className="western-button-link">
                <div style={{ display: "flex", justifyContent: "space-evenly"}}>
                    <p>Call Driver Payroll</p>
                    <a href="tel:615-777-1682">(615) 777-1682</a>
                </div>
            </div>
            <Link className="western-button-link" to="/contracts">
                <div>
                    <img alt="key" className="link-icon" src={documentIcon} /><p>Contracts</p>
                </div>
            </Link>

        </div>
    )
}

export default Money
