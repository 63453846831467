import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import ContactCard from './ContactCard';
import {ApiService} from "../api/ApiService";
import {ContactsInfo} from "../api/model/Contacts";

const SafetyContacts: React.FC = () => {

  const [contacts, setContacts] = useState<any>({});

  useEffect(() => {
      ApiService.getContacts("safetyContacts", {category: 'safety'}).then(it => parseContacts(it));
  }, [])

  function parseContacts(contacts: ContactsInfo[]) {
    let tempArray: any[] = contacts;
    let tempObj: any = {}
    tempArray.forEach(element => {
      if(typeof tempObj[element.subcategory] !== 'undefined') {
        tempObj[element.subcategory].push(element)
      } else {
        tempObj[element.subcategory] = [element]
      }
    });
    setContacts(tempObj);
  }

  return (
    <IonPage>
 <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Safety Contacts</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
          <div style={{marginBottom: "9vh"}}>
          {Object.keys(contacts).map(key => {
              let element: any = contacts[key]
              let safetyCard = <div key={key}>
                {element.map((val: any, index: number) => {
                  let content = <IonItem lines="none" key={index}><ContactCard contact={val}/></IonItem>
                  return content
                })}</div>        
            return safetyCard 
              })}
          </div>
      </IonContent>
    </IonPage>
  );
};

export default SafetyContacts;
