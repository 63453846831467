import React, {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {close} from 'ionicons/icons';
import {config} from '../Constants'
import documentIcon from "../imgs/document.png"
import {ApiService} from "../api/ApiService";
import {DocumentInfo} from "../api/model/DocumentsList";
import {VideoInfo} from "../api/model/VideosList";
import { DriverProfile } from '../api/model/DriverProfile';
import { UserState, userStore } from '../api/AuthService';

const Registrations : React.FC = () => {

  const {userInfo}: { userInfo: DriverProfile } = userStore((state: UserState) => ({userInfo: state.driver}));

  const [showModalDoc, setShowModalDoc] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState<any>({});
  const [docs, setDocList] = useState<any>([]);

  useEffect(() => {
    ApiService.getRegistrations("registrations", {unitNumber: userInfo.unit}).then(it => parseRegistrations(it));
    ApiService.sendEventCommand("Checked Permit", "eventCheck", "User checked the permits page");
  }, []);

  function parseRegistrations(registrationList: any) {
    let tempArray: any[] = []
    registrationList.documents.forEach((element: any) => {
        console.log("temparray", registrationList.documents[registrationList.documents.length - 1]);
        if(element === registrationList.documents[registrationList.documents.length - 1]) {
            tempArray.push(element);
        }
    });
    console.log("registrationList", tempArray);
    setDocList(tempArray)
  }

  return (
    <IonPage >
      <IonHeader >
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Permits</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent  fullscreen>

    <IonModal isOpen={showModalDoc} cssClass='my-custom-class'>
        <IonHeader >
          <IonToolbar color="primary">
            <IonButtons slot="start">
                <button onClick={ function() { setShowModalDoc(false); }} style={{background: "transparent"}}>
                    <h6>Back</h6>
                </button>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen style={{'--color': 'black'}}>
            <div style={{}}>
                <img src={'data:image/jpg;base64, ' +  selectedDoc.extractedDoc}></img>
            </div>
        </IonContent>
    </IonModal>

        <div className='main-30-70'>
          <div>
       <IonList style={{ padding: '8px'}}>
       <h1 style={{textAlign: 'center', color: 'black'}}>Documents</h1>
       {docs.map(function(doc: any){
         let id = doc.docId
         return <IonItem key={id} onClick={() => {setSelectedDoc(doc); setShowModalDoc(true)}} lines="none">
                    <div className='item-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p>Registration: Date {doc.uploadDate}</p>
                        <img src={documentIcon} className="link-icon" alt="doc"/>
                    </div>
                    {/* <IonLabel text-wrap>{title}</IonLabel>
                    <IonIcon slot="end" icon={chevronForward}></IonIcon> */}
                </IonItem>
        })} 
        </IonList></div>
        </div>
      
      
      </IonContent>
    </IonPage>
  );
};

export default Registrations;

