import React from 'react';
import {IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import ContactCard from './ContactCard';


const ChainOfCommand: React.FC = () => {

  return (
    <IonPage>
 <IonHeader>
        <IonToolbar color="primary">
        <IonButtons slot="start">
            <IonBackButton defaultHref="/Home" text=""/>
          </IonButtons>
          <IonTitle>Training Contact</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonItem lines="none" color='light'><ContactCard contact={{phone: "615-277-7615", email: "traineesupport@westernexp.com", name: "Training Contact Info", subcategory: "Training"}}/></IonItem>


          {/* Workbench - "Inline" TSX
            <IonCard style={{border: "2px solid white", color:"white"}} >
            <IonCardHeader>
              <IonCardTitle onClick={e => collapseContent("content")}>Contact List:</IonCardTitle>
            </IonCardHeader>
            <IonCardContent class="dropdown-list" id="content">
              <div>
                <div style={{ backgroundColor: 'darkgray', padding: '15px', borderBottom: '3px solid #ddd' }}>
                  <b style={{ fontSize: '14px' }}>Title: [auto_gen]1</b>
                  <h5 style={{ color: "black", fontSize: "14px", fontWeight: 'bold' }}>-- ['INSERT_NAME']</h5>
                  <p>Phone Number: <a href="tel:555-555-5100">555-100-####</a></p>
                  <p>Email: <a href="first.last@westernexp.com">first.last@westernexp.com</a></p>
                </div>
              </div><div>
                  <div style={{ backgroundColor: 'darkgray', padding: '15px', borderBottom: '3px solid #ddd' }}>
                    <b style={{ fontSize: '14px' }}>Title: [auto_gen]2</b>
                    <h5 style={{ color: "black", fontSize: "14px", fontWeight: 'bold' }}>-- ['INSERT_NAME']</h5>
                    <p>Phone Number: <a href="tel:555-555-5100">555-100-####</a></p>
                    <p>Email: <a href="first.last@westernexp.com">first.last@westernexp.com</a></p>
                  </div>
                </div><div>
                  <div style={{ backgroundColor: 'darkgray', padding: '15px', borderBottom: '3px solid #ddd' }}>
                    <b style={{ fontSize: '14px' }}>Title: [auto_gen]3</b>
                    <h5 style={{ color: "black", fontSize: "14px", fontWeight: 'bold' }}>-- ['INSERT_NAME']</h5>
                    <p>Phone Number: <a href="tel:555-555-5100">555-100-####</a></p>
                    <p>Email: <a href="first.last@westernexp.com">first.last@westernexp.com</a></p>
                  </div>
                </div><div>
                  <div style={{ backgroundColor: 'darkgray', padding: '15px', borderBottom: '3px solid #ddd' }}>
                    <b style={{ fontSize: '14px' }}>Title: [auto_gen]4</b>
                    <h5 style={{ color: "black", fontSize: "14px", fontWeight: 'bold' }}>-- ['INSERT_NAME']</h5>
                    <p>Phone Number: <a href="tel:555-555-5100">555-100-####</a></p>
                    <p>Email: <a href="first.last@westernexp.com">first.last@westernexp.com</a></p>
                  </div>
                </div>
             
              
            </IonCardContent>
          </IonCard>
          */}

          
      
      
      </IonContent>
    </IonPage>

  );
};

export default ChainOfCommand;

