import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        let slug:any = ""
        if (event.url.indexOf('.com') !== -1) {
          slug = event.url.split('.com').pop();
          if (slug) {
            history.push(slug);
          }
        } else {
          slug = event.url.split('.app').pop();
          if (slug) {
            history.push(slug);
          }
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, []);
  
    return null;
  };
  
  export default AppUrlListener;