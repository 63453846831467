import React from "react";
import passengerIcon from "../imgs/add-passenger.png"
import safetyIcon from "../imgs/traffic-light.png"
import maintenanceIcon from "../imgs/maintenance.png"
import benefitsIcon from "../imgs/benefits.png"
import accidentsIcon from "../imgs/airbag.png"
import documentIcon from "../imgs/document.png"
import inspectionIcon from "../imgs/doc-check.png"


import scanIcon from '../imgs/work/qr-scan.png';
import {Link} from "react-router-dom";
const Docs = () => {
    return (
        <div className="item-container">
            <div className="grid50">
                <Link className="western-button-link" to="/safety">
                    <div>
                        <img src={safetyIcon} alt="key" className="link-icon"/><p>Safety</p>
                    </div>
                </Link>
              
                <Link className="western-button-link" to="/maintenancevids">
                    <div>
                        <img src={maintenanceIcon} alt="key" className="link-icon"/><p>Maintenance</p>
                    </div>
                </Link>
                <Link className="western-button-link" to="/safetyscore">
                    <div>
                        <img src={inspectionIcon} alt="key" className="link-icon"/><p>Safety Score</p>
                    </div>
                </Link>
                <Link className="western-button-link" to="/benefits">
                    <div>
                        <img src={benefitsIcon} alt="key" className="link-icon"/><p>Benefits</p>
                    </div>
                </Link>
            </div>
            <Link className="western-button-link" to="/registrations">
                <div>
                    <img alt="key" className="link-icon" src={documentIcon} /><p>Registrations</p>
                </div>
            </Link>
            <Link className="western-button-link" to="/permits">
                <div>
                    <img alt="key" className="link-icon" src={documentIcon} /><p>Permits</p>
                </div>
            </Link>
            <Link className="western-button-link" to="/accidentinfo">
                <div className="red">
                    <img src={accidentsIcon} alt="key" className="link-icon"/><p>Accidents</p>
                </div>
            </Link>
        </div>
    )
}

export default Docs
