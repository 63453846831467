export class ValidationUtils {

    public static isObjectDefined(object?: any): boolean {
        return object !== undefined && object !== null;
    }

    public static allObjectsDefined(...objects: any[]): boolean {
        return !objects.some(it => !ValidationUtils.isObjectDefined(it));
    }

}
