import React, {useEffect, useState} from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import historyIcon from "../imgs/clock-history.png"
import callIcon from "../imgs/call.png"
import historyActiveIcon from "../imgs/clock-history-active.png"

import {checkmarkCircle, closeCircle} from 'ionicons/icons';

//Google Analytics:
import {getAnalytics, logEvent} from 'firebase/analytics';
import {initializeApp} from "firebase/app";
import {ApiService} from "../api/ApiService";
import {toastStore} from "../components/AppToast";
import {userStore} from "../api/AuthService";
import { Link } from 'react-router-dom';

var moment = require('moment');

let mustWatch = false;

interface AdvanceHistory {
  date: string,
  amount: string,
  status: string
}

const PersonalAdvance: React.FC = () => {

  const [reason, setReason] = useState<string>();
  const [showHistory, setShowHistory] = useState(false)
  const [advanceAmount, setAdvanceAmount] = useState<number>();
  const [available, setAvailable] = useState<string>();
  const [showModal, setShowModal] = useState(mustWatch);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [advances, setAdvances] = useState<AdvanceHistory[]>([]);
  const [dmPhone, setDMPhone] = useState('');
  const [watchedVid, setWatchedVid] = useState(false);
  const [moneyAvailable, setMoneyAvailable] = useState(false);
  const [isDispatched, setIsDispatched] = useState(false);
  const [hasPaperwork, setHasPaperwork] = useState(false);
  const [owesWestern, setOwesWestern] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [cashAdvanceAvailable, setCashAdvanceAvailable] = useState("1");
  
  const firebaseConfig = {
    apiKey: "AIzaSyDE8xEN_qtMPO-aBkTon7S7kKcyUZJD5gc",
    authDomain: "western-express-5002c.firebaseapp.com",
    databaseURL: "https://western-express-5002c.firebaseio.com",
    projectId: "western-express-5002c",
    storageBucket: "western-express-5002c.appspot.com",
    messagingSenderId: "799211811572",
    appId: "1:799211811572:web:34706f63a8621cb0b0a4a1",
    measurementId: "G-V5MNJV768B"
  }

  const app = initializeApp(firebaseConfig)
  
  const handleEventLog = (eventType: string, info: string) => {
    const analytics = getAnalytics(app);
    logEvent(analytics , eventType, {eventType: info})
    console.log(eventType);
  }
  useEffect(() => {
    ApiService.getUserState("personalAdvance", {name: 'cashAvailable', userId: 0}).then(it => setCashAdvanceAvailable(it.value));
    ApiService.getDriverManager("personalAdvance", {dmCode: userStore.getState().driver?.dmCode}).then(it => setDMPhone(it?.phone));
    ApiService.getVideosList("personalAdvance", {type: 'other'}).then(it => setVideoLink(it.find(video => video.title === 'Cash Advance Video')?.youtube!));
    if (advances?.length === 0) {
      ApiService.getCashAdvances("personalAdvance", ).then(it => {
        setAdvances(it.map(advance => {
          return {
            date: moment(advance.date).format('M/D/YYYY'),
            amount: advance.amount,
            status: advance.status
          }
        }));
      });
    }
    ApiService.getCashAdvanceEligibility("personalAdvance", ).then(it => {
      setMoneyAvailable(parseFloat(it.available) > 0);
      setAvailable(it.available);
      setIsDispatched(!it.notDispatched);
      setHasPaperwork(!it.paperworkIncomplete);
      setOwesWestern(it.owing);
    });

    // Forced Video
    let last_watched = localStorage.getItem('lastwatchedadvance')
    if (last_watched) {
      // If true: the video has been watched in the last 24 hours
      if ((new Date().getTime() - new Date(last_watched).getTime()) < 86400000) {
        mustWatch = false
        return
      }
    }
    if (watchedVid) {
      let string = new Date()
      localStorage.setItem('lastwatchedadvance', string.toString())
    } else {
      if (cashAdvanceAvailable === "1") {
        mustWatch = true
        setShowModal(mustWatch)
      }
    }
    // eslint-disable-next-line
  }, [watchedVid]);

  async function submitRequest() {
    ApiService.sendEventCommand("Request Advance", "eventCheck", "User requested an advance");
    ApiService.submitCashAdvance("personalAdvance", {amount: advanceAmount!, comment: reason!}).then(() => {
      toastStore.getState().setSuccessToast('Your advance has been requested.');
    });
  }

  return (
    <IonPage>
    <IonHeader >
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/Home"  text=""/>
          </IonButtons>
          <IonTitle>Personal Advance</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonModal isOpen={showModal} id="transparent">
            <div className="modal-div" onClick={() => setShowModal(false)}>
                <div className="item-container" id="modal">
                  <p className='title'>Please watch this brief video before continuing</p>
                  <iframe style={{marginTop: "10px"}} src={"https://www.youtube.com/embed/" + videoLink} onEnded={ function() { mustWatch = false; setWatchedVid(true); setShowModal(false)}}></iframe>

                </div>
            </div>
        </IonModal>
        <IonModal isOpen={showCreateModal} id="transparent">
            <div className="modal-div" onClick={() => setShowCreateModal(false)}>
                <div className="item-container" id="modal">
                  <h4 className='title'>How to Create an EFS Account:</h4>
                  <p style={{margin: "10px"}}>To create a EFS driver profile please visit the following site: <br/><br/><Link to={"https://emgr.efsllc.com/security/logon.jsp"}>https://emgr.efsllc.com/security/logon.jsp</Link> <br/><br/>and fill out the Card number and PIN fields with your card information.</p>
                </div>
            </div>
        </IonModal>
        <IonModal isOpen={showAccountModal} id="transparent">
            <div className="modal-div" onClick={() => setShowAccountModal(false)}  style={{overflowY: 'scroll'}}>
                <div className="item-container" id="modal">
                  <h4 className='title'>How to Create an EFS Account:</h4>
                  <p style={{margin: "10px"}}><b>1.</b> Using your EFS card and an ATM, you may withdraw money from the checking account. Beware that many ATM's have a $200 withdraw limit per transaction.</p>
                  <p style={{margin: "10px"}}><b>2.</b> Sign an EFS check made out to you using your EFS card number as the MONEY CODE. Be aware that most banks will except a maximum limit of $400 per check. 
                  After filing out the check, call the number on front of the check: 888-824-7378. Selecting option 3, following the automated prompts when it asks for your EFS pin and car number. You will then 
                  be prompted for the check information: amount, check number and money code. You will then be given an authorization number to write onto the check. After filling out the Authorization Signature 
                  Code field you can turn the check in to a bank teller or station clerk. After verifying the check they will cash the check out to you there.</p>
                  <p style={{margin: "10px"}}><b>3.</b> Download the WEX CardControl app available on both the Google Play Store and the iOS Store. Upon opening the app for the first time, enter your EFS card 
                  number and pin, this will log you in to the account for your card. Open the options menu in the top left and select Transfer Funds, then enter your banking information to add your bank to the card. 
                  Once the bank is verified, you may transfer any funds from your EFS account to your personal account. Transfers can take up to 10 days to complete, transaction fees vary according to bank.</p>
                </div>
            </div>
        </IonModal>
      {cashAdvanceAvailable === "0" ?
            <IonCard style={{"--background": "#fff", textAlign: "center"}}>
              <IonCardHeader>
                <IonLabel style={{fontSize: "20px", fontWeight: "600", color: "black"}}>Cash Advance is currently unavailable</IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonCardSubtitle style={{color: "#666667"}}><b>Contact your DM for<br/> more details or questions</b></IonCardSubtitle>
                <a href={`tel:${dmPhone}`} className="western-button-link">
                  <div>
                      <img src={callIcon} alt="key" style={{ height: "min(5vw, 22px)", margin: '4px', backgroundColor: "#69E175", padding: "4px", borderRadius: "50%"}}/><p>Call DM</p>
                  </div>
                </a>
              </IonCardContent>
            </IonCard>: <>
      {/* <IonModal isOpen={showModal}>
        <IonContent>
        <IonHeader >
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonButton onClick={ function() { setShowModal(false);}}>
                <IonIcon slot="icon-only" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <iframe style={{marginTop: "10px"}} src={"https://www.youtube.com/embed/" + videoLink} onEnded={ function() { mustWatch = false; setWatchedVid(true); setShowModal(false)}}></iframe>
          </IonContent>
        </IonModal> */}
        {/* <IonModal isOpen={true && !false} id="transparent">
        <IonItem lines="none">
          <div className='item-container'> 
            <button className="western-button" onClick={ function() { setShowAlert(false)}}>Done</button>
          </div>
        </IonItem>  
        </IonModal> */}
          <IonItem lines="none" color="light">
            <div className='item-container'>
              <p className='title' style={{ fontSize: "x-large"}}>New Request</p>
              <span style={{fontSize: "12px", lineHeight: '20px'}}><div onClick={e => {setShowCreateModal(true)}} style={{textDecoration: 'underline'}}>- How to create an EFS Account</div><br/><div onClick={e => {setShowAccountModal(true)}} style={{textDecoration: 'underline'}}>- How to get money from your account</div></span>
              {advances.length ? <p >Current Advance: ${advances[0].amount}<br/>Status: <span style={{color: advances[0].status === "approved" ? "#2fd573" : advances[0].status === "pending" ? "#ffca22" : "#000"}}>{advances[0].status}</span></p> : <p>You do not have any active requests.</p>}
            {showHistory ? <div className="report-history" >
                    <div style={{ textAlign: "center", position: "relative"}} onClick={() => setShowHistory(!showHistory)}><img src={historyActiveIcon} style={{ height: "min(5vw, 22px)", position: "absolute", left: "8px", top: "8px"}} />
                    <p className="subtitle">Show Less</p></div>
                    <IonItem lines="none" color="white">
                            <div className="item-container">
                                <p style={{ margin: 0}}>Request History</p>
                            </div>
                        </IonItem>
                    {advances.map(function(adv,index) {
                     
                        return (
                          <div key={index} className="item-container space-between"><div><p>Amount: ${adv.amount}</p><p className='subtitle'>Date: {adv.date}</p></div><p style={{color: adv.status === "approved" ? "#2fd573" : adv.status === "pending" ? "#ffca22" : "#000"}}>{adv.status}</p></div>
                        )
            
                    })}
                     <IonItem lines="none" color="white">
                            <div className="item-container">
                                <p className="subtile">Showing {advances.length} result(s).</p>
                            </div>
                        </IonItem>
                </div> :
                  <div style={{display: "flex", alignItems: "center"}}><p className="subtitle" style={{  margin: 0 }}>See History</p><img src={historyIcon} style={{ height: "2.5vh"}} onClick={() => setShowHistory(!showHistory)}/></div>
                }
            </div>
            
          </IonItem>
          <br/>
          <IonItem lines="none" color="light">
            <p className='title' style={{ fontSize: "large"}}>Am I Eligible for an advance?</p>   
          </IonItem>
          <IonItem lines="none" color="light">
            <div className='item-container'>
            {moneyAvailable ? <div className='item-container'>
              <div style={{ display: 'flex', justifyContent: "space-between", color: "#666667", alignItems: "center"}}><strong>You have up to ${available}<br/> available for advance.</strong><IonIcon style={{fontSize: "x-large", color: "#71FE83"}} icon={checkmarkCircle}></IonIcon></div>
            </div> :  <div className='item-container'>
              <div style={{ display: 'flex', justifyContent: "space-between", color: "#666667", alignItems: "center"}}><strong>You have $0 available for advance.</strong><IonIcon style={{fontSize: "x-large", color: "#FF0000"}} icon={closeCircle}></IonIcon></div>
            </div>}
            {isDispatched ? <div className='item-container'>
              <div style={{ display: 'flex', justifyContent: "space-between", color: "#666667", alignItems: "center"}}><strong>You are dispatched</strong><IonIcon style={{fontSize: "x-large", color: "#71FE83"}} icon={checkmarkCircle}></IonIcon></div>
            </div> : <div className='item-container'>
              <div style={{ display: 'flex', justifyContent: "space-between", color: "#666667", alignItems: "center"}}><strong>You are not dispatched</strong><IonIcon style={{fontSize: "x-large", color: "#FF0000"}} icon={closeCircle}></IonIcon></div>
            </div>}
            {hasPaperwork ? <div className='item-container'>
              <div style={{ display: 'flex', justifyContent: "space-between", color: "#666667", alignItems: "center"}}><strong>You are up to date on <br/>your paperwork.</strong><IonIcon style={{fontSize: "x-large", color: "#71FE83"}} icon={checkmarkCircle}></IonIcon></div>
            </div> : <div className='item-container'>
              <div style={{ display: 'flex', justifyContent: "space-between", color: "#666667", alignItems: "center"}}><strong>You are not up to date on<br/> your paperwork.</strong><IonIcon style={{fontSize: "x-large", color: "#FF0000"}} icon={closeCircle}></IonIcon></div>
            </div>}
            {!owesWestern ? <div className='item-container'>
              <div style={{ display: 'flex', justifyContent: "space-between", color: "#666667", alignItems: "center"}}><strong>You are not in the owing position.</strong><IonIcon style={{fontSize: "x-large", color: "#71FE83"}} icon={checkmarkCircle}></IonIcon></div>
            </div> : <div className='item-container'>
              <div style={{ display: 'flex', justifyContent: "space-between", color: "#666667", alignItems: "center"}}><strong>You are in the owing position.</strong><IonIcon style={{fontSize: "x-large", color: "#FF0000"}} icon={closeCircle}></IonIcon></div>
            </div>}
            { owesWestern || !hasPaperwork || !available || !isDispatched ?
            <IonCard style={{"--background": "transparent"}}>
              <IonCardHeader>
                <IonLabel style={{fontSize: "20px", fontWeight: "600", textAlign: 'center', color: "black"}}>You are NOT currently<br/>eligable for an advance</IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonCardSubtitle style={{color: "#666667", textAlign: "center"}}>Contact your DM for<br/> more details or questions</IonCardSubtitle>
                <a href={`tel:${dmPhone}`} className="western-button-link">
                        <div>
                            <img src={callIcon} alt="key" style={{ height: "min(5vw, 22px)", margin: '4px', backgroundColor: "#69E175", padding: "4px", borderRadius: "50%"}}/><p>Call DM</p>
                        </div>
                </a>
              </IonCardContent>
            </IonCard> :
            <>
              <div className="input-container">
                <input id="reason" className="input" type="text" placeholder=" " value={reason} onChange={e => setReason(e.target.value!)}/>
                <p className="placeholder" >{'Reason(optional)'}</p>
              </div>
              <div className="input-container">
                <input id="amount" className="input" type="number" placeholder=" " value={advanceAmount} onChange={e => setAdvanceAmount(parseFloat(e.target.value!))}/>
                <p className="placeholder" >{'Amount'}</p>
              </div>
              <div className='item-container'><p className='subtitle'>If approved, this advance will be deducted from your paycheck.</p></div>
              <IonButton id="western-button-submit" onClick={e => {submitRequest(); handleEventLog("advance_PayRequest", "submit-PayRequest")}}>Submit</IonButton>
            </>}
            </div>
          </IonItem>
    
      </> 
      }
      </IonContent>
      
    </IonPage>
  );
};

export default PersonalAdvance;
