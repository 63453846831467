import {ValidationUtils} from "../../../utils/ValidationUtils";

export class TrainerFeedbackRequest {
    traineeCode?: string;
    trainerCode?: string;
    overallScore?: number;
    cleanliness?: number;
    policyScore?: number;
    conductScore?: number;
    logTraining?: number;
    overallExp?: number;
    dotReg?: number;
    refWEXP?: number;
    refTrainer?: number;
    complaint?: string;
    comments?: string;
}

export class TrainerFeedbackRequestUtils {
    public static isValid(request: TrainerFeedbackRequest): boolean {
        return !!request.traineeCode && !!request.trainerCode && ValidationUtils.allObjectsDefined(
            request.overallScore,
            request.cleanliness,
            request.policyScore,
            request.conductScore,
            request.logTraining,
            request.overallExp,
            request.dotReg,
            request.refWEXP,
            request.refTrainer
        );
    }
}
